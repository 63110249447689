import * as React from "react";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../hooks/useServices";
import {useTrackState} from "../../../../hooks/useTrackState";
import {TrackOwnerType} from "../../../../../../shared/types/TrackOwnerType";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";

/******************************************************************
 * TrackDeleteSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackDeleteSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {trackName, trackPath, trackID, trackOwner, trackOwnerType} = useTrackState();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function deleteTrack() {
        state.showConfirm.setValue({
            type: "deleteTrack",
            payload: {
                trackName: trackName,
                trackPath: trackPath,
                trackID: trackID,
                trackOwner: trackOwner,
                trackOwnerType: trackOwnerType as TrackOwnerType,
            }
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="track-delete-settings"
            alignSelf="start"
            justifyItems="center">
            <Spacer height="large"/>
            <InfoText
                text={dict("track.delete.info")}
                align="center"/>
            <LabelButton
                label={dict("track.delete.button.label")}
                onClick={deleteTrack}
                style="primary"/>
        </ContentLayout>
    );

}
