import * as React from "react";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {Picture} from "../../image/Picture";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {IEventData} from "../../../../../shared/models/IEventData";
import {Icon} from "../../icons/Icon";
import {IconType} from "../../icons/IconType";
import {useGroupState} from "../../../hooks/useGroupState";
import {InfoText} from "../../text/infotext/InfoText";
import {TrackUtils} from "../../../../utils/TrackUtils";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useEventState} from "../../../hooks/useEventState";

/******************************************************************
 * TrackInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackInfo(props: {
    trackData: ITrackData
    eventData?: IEventData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router} = useServices()
    const {dict} = useServices()
    const {isGroupMember, isGroupAdmin} = useGroupState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {eventOwnerType} = useEventState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoTrack() {
        if (!hasUserAccess()) {
            return
        }
        if (!props.eventData) {
            router.showRoute(FrontendRoute.TRACK(
                props.trackData.ownerType,
                props.trackData.owner.path,
                props.trackData.path))
            return
        }
        router.showRoute(FrontendRoute.EVENT_TRACK(
            props.eventData.ownerType,
            props.eventData.owner.path,
            props.eventData.path,
            props.trackData.path))
    }

    function privacyIconType(): IconType {
        return TrackUtils.trackPrivacyIconType(props.trackData.privacy)
    }

    function hasUserAccess(): boolean {
        return TrackUtils.hasUserAccess(
            props.trackData.privacy,
            isGroupMember,
            isGroupAdmin,
            isRouteOwnerAuthUser)
    }

    function notAvailableText(): string {
        return dict(TrackUtils.trackNotAvailableDictKey(
            props.trackData.privacy,
            eventOwnerType == "user"))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="track-info"
            onClick={gotoTrack}
            data-no-access={!hasUserAccess()}>
            {hasUserAccess()
                ? <>
                    <Picture
                        file={props.trackData?.image}
                        alt={props.trackData?.name}/>
                    <div className="track-info-title">
                        <h2>{props.trackData?.name}</h2>
                        {props.trackData?.layout && <h3>{props.trackData?.layout}</h3>}
                    </div>
                    {props.trackData.privacy != "public" &&
                        <div className="track-info-privacy-icon">
                            <Icon
                                type={privacyIconType()}
                                scale={0.5}/>
                        </div>}
                </>
                : <>
                    <div className="track-info-icon-frame">
                        <Icon
                            type={privacyIconType()}
                            scale={0.8}/>
                    </div>
                    <InfoText
                        text={notAvailableText()}
                        align="left"/>
                </>}
        </div>
    );

}
