import {FrontendServices} from "../FrontendServices"
import {FrontendConfig} from "../FrontendConfig"
import {FrontendRoute} from "../../../shared/routes/FrontendRoute"
import {SharedDictionaryKeys} from "../../../shared/keys/SharedDictionaryKeys"
import {FrontendRouteUtils} from "../../../shared/utils/FrontendRouteUtils"
import {IFrontendRoute} from "../../utils/interfaces/IFrontendRoute"
import {IUserData} from "../../../shared/models/IUserData"
import {ISessionData} from "../../../shared/models/ISessionData"
import {IEventData} from "../../../shared/models/IEventData"
import {ITrackData} from "../../../shared/models/submodels/ITrackData"
import {IGroupData} from "../../../shared/models/IGroupData"
import {IFrontendEventRoute} from "../../../shared/types/IFrontendEventRoute"
import {TrackOwnerType} from "../../../shared/types/TrackOwnerType"
import {EventOwnerType} from "../../../shared/types/EventOwnerType"
import {IResultData} from "../../../shared/models/IResultData"
import {FrontendBaseRouteType} from "../../../shared/types/FrontendBaseRouteType";
import {DeviceUtils} from "../../utils/DeviceUtils";

/******************************************************************
 * FrontendRouter
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendRouter {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _initialized = false

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
        this.initListeners()
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public get currentFrontendRoute(): string {
        return document.location.href.split(FrontendConfig.BASE_URL)[1].split("?")[0]
    }

    public showInitRoute() {
        const hasAuthUser = !!this._frontend.state.authUser.getValue()?._id
        const isRunningAsPWA = DeviceUtils.isRunningAsPWA()
        if (hasAuthUser && isRunningAsPWA) {
            let route = localStorage.getItem("latestRoute") ?? this.currentFrontendRoute
            this.showRoute(route, true);
        } else {
            this.showRoute(this.currentFrontendRoute)
        }
        this._initialized = true
    }

    public async refreshRoute() {
        if (!this._frontend.socket.isConnected || !this._initialized) return
        await this._frontend.time.updateTimeFromServer()
        await this.showRoute(this.currentFrontendRoute, false, false)
    }

    public async showRoute(route: string, pushToHistory: boolean = true, hideModals: boolean = true) {
        route = route.split("#")[0]
        const routeType = FrontendRouteUtils.getRouteType(route)
        this.updateMobileMenuState(routeType)
        this.resetDataStates(route)
        this.saveRouteToLocalStorage(route)
        if (hideModals) {
            this.hideModals()
        }
        switch (routeType) {
            case "home":
                this.showHome(pushToHistory)
                break
            case "groups":
                this.showGroups(pushToHistory)
                break
            case "events":
                this.showEvents(pushToHistory)
                break
            case "plans":
                this.showPlans(pushToHistory)
                break
            case "admin":
                this.showAdminRoute(route, pushToHistory)
                break
            case "member":
                await this.showUserRoute(route, pushToHistory)
                break
            case "group":
                await this.showGroupRoute(route, pushToHistory)
                break
            case "event":
                await this.showEventRoute(route, pushToHistory)
                break
            case "track":
                await this.showTrackRoute(route, pushToHistory)
                break
        }
        this.showModal(route)
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    /* ----------------------------------------------------------------
 	 * init Listeners
 	 * --------------------------------------------------------------*/

    private initListeners() {
        window.addEventListener("popstate", () => this.onPopState())
        window.addEventListener('focus', () => this.refreshRoute())
        this._frontend.state.socket.onChangeSignal.add(() => this.refreshRoute())
    }

    /* ----------------------------------------------------------------
 	 * UPDATE MOBILE MENU STATE
 	 * --------------------------------------------------------------*/

    private updateMobileMenuState(routeType: FrontendBaseRouteType) {
        const currentRouteType = FrontendRouteUtils.getRouteType(this.currentFrontendRoute)
        let showMobileMenu = routeType == "admin" && currentRouteType != "admin"
        this._frontend.state.showMobileMenu.setValue(showMobileMenu)
    }

    /* ----------------------------------------------------------------
 	 * SHOW HOME
 	 * --------------------------------------------------------------*/

    private showHome(pushToHistory: boolean) {
        const authUser = this._frontend.state.authUser.getValue()
        if (authUser) {
            this.showRoute(FrontendRoute.USER(authUser.path), pushToHistory)
            return
        }
        this.updateRouteState({
            route: FrontendRoute.HOME,
            type: "home",
            title: this._frontend.dict.get(SharedDictionaryKeys.HOME_DICT_KEY)
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * SHOW GROUPS
 	 * --------------------------------------------------------------*/

    private showGroups(pushToHistory: boolean) {
        const isLoggedIn = this._frontend.state.authUser.hasValue()
        if (isLoggedIn) {
            this.showHome(pushToHistory)
            this._frontend.state.showGroupsFinder.setValue(true)
            return
        }
        this.updateRouteState({
            route: FrontendRoute.GROUPS,
            type: "groups",
            title: this._frontend.dict.get(SharedDictionaryKeys.GROUPS_DICT_KEY)
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * SHOW EVENTS
 	 * --------------------------------------------------------------*/

    private showEvents(pushToHistory: boolean) {
        const isLoggedIn = this._frontend.state.authUser.hasValue()
        if (isLoggedIn) {
            this.showHome(pushToHistory)
            this._frontend.state.showEventsFinder.setValue(true)
            return
        }
        this.updateRouteState({
            route: FrontendRoute.EVENTS,
            type: "events",
            title: this._frontend.dict.get(SharedDictionaryKeys.EVENTS_DICT_KEY)
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * SHOW PLANS
 	 * --------------------------------------------------------------*/

    private showPlans(pushToHistory: boolean) {
        const isLoggedIn = this._frontend.state.authUser.hasValue()
        if (isLoggedIn) {
            this.showHome(pushToHistory)
            this._frontend.state.showSubscriptionPlans.setValue(true)
            return
        }
        this.updateRouteState({
            route: FrontendRoute.PLANS,
            type: "plans",
            title: this._frontend.dict.get(SharedDictionaryKeys.PLANS_DICT_KEY)
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * SHOW ADMIN
 	 * --------------------------------------------------------------*/

    private showAdminRoute(route: string, pushToHistory: boolean) {
        const authUser = this._frontend.state.authUser.getValue()
        if (!authUser || authUser.role !== "admin") {
            this.showRoute(FrontendRoute.HOME, pushToHistory)
            return
        }
        if (route == FrontendRoute.ADMIN) {
            route = FrontendRoute.ADMIN_LOGS
        }
        this.updateRouteState({
            route: route,
            type: "admin",
            title: this._frontend.dict.get(SharedDictionaryKeys.ADMIN_TITLE_DICT_KEY)
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * SHOW USER/MEMBER
 	 * --------------------------------------------------------------*/

    private async showUserRoute(route: string, pushToHistory: boolean) {
        const userRoute = FrontendRouteUtils.parseUserRoute(route)
        const userData = await this.getRouteUserState(userRoute?.userPath)
        if (!userData) {
            this.showRoute(FrontendRoute.HOME, pushToHistory)
            return
        }
        this._frontend.state.routeOwner.setValue(userData)
        this.updateRouteState({
            route: route,
            type: "member",
            title: userData.nick
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * SHOW GROUP
 	 * --------------------------------------------------------------*/

    private async showGroupRoute(route: string, pushToHistory: boolean) {
        const groupRoute = FrontendRouteUtils.parseGroupRoute(route)
        const ownerData = await this.getOwnerData("group", groupRoute?.groupPath)
        const groupData = await this.getRouteGroupState(groupRoute?.groupPath)
        if (!groupData) {
            if (this._frontend.state.route.hasValue()) {
                return
            }
            this.showRoute(FrontendRoute.HOME, pushToHistory)
            return
        }
        if (!groupRoute.hasSubPath) {
            route = FrontendRoute.GROUP(groupRoute.groupPath)
        }
        this._frontend.state.routeOwner.setValue(ownerData)
        this._frontend.state.group.setValue(groupData)
        this.updateRouteState({
            route: route,
            type: "group",
            title: groupData.name
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * SHOW EVENT
 	 * --------------------------------------------------------------*/

    private async showEventRoute(route: string, pushToHistory: boolean) {
        const eventRoute = FrontendRouteUtils.parseEventRoute(route)
        if (!eventRoute) {
            this.showRoute(FrontendRoute.HOME, pushToHistory)
            return
        }
        const ownerData = await this.getOwnerData(eventRoute.ownerType, eventRoute.ownerPath)
        const eventData = await this.getEventState(eventRoute)
        if (!eventData) {
            switch (eventRoute.ownerType) {
                case "user":
                    return this.showUserRoute(FrontendRoute.USER_TRAINING(ownerData.path), pushToHistory)
                case "group":
                    if (!ownerData) {
                        return this.showRoute(FrontendRoute.HOME, pushToHistory)
                    }
                    return this.showGroupRoute(FrontendRoute.GROUP_EVENTS(ownerData.path), pushToHistory)
            }
        }
        const eventChildren = await this._frontend.api.event.getChildren(eventData._id)
        let sessionData: ISessionData = null
        let resultData: IResultData = null
        let title = eventData.name
        switch (eventRoute.type) {
            case "result":
                resultData = await this._frontend.api.result.getResultByRoute(route)
                title = resultData?.name + " | " + eventData.name
                if (!resultData) {
                    return this.showEventRoute(
                        FrontendRoute.EVENT(
                            eventRoute.ownerType,
                            eventRoute.ownerPath,
                            eventRoute.eventPath),
                        pushToHistory)
                }
                break
            case "session":
                sessionData = await this._frontend.api.session.getSessionByRoute(route)
                title = sessionData?.name + " | " + eventData.name
                if (!sessionData) {
                    return this.showEventRoute(
                        FrontendRoute.EVENT(
                            eventRoute.ownerType,
                            eventRoute.ownerPath,
                            eventRoute.eventPath),
                        pushToHistory)
                }
                break
        }
        this._frontend.state.routeOwner.setValue(ownerData)
        switch (eventRoute.ownerType) {
            case "user":
                this._frontend.state.group.setValue(null)
                break
            case "group":
                this._frontend.state.group.setValue(ownerData)
                break
        }
        this._frontend.state.event.setValue(eventData)
        this._frontend.state.eventChildren.setValue(eventChildren)
        this._frontend.state.session.setValue(sessionData)
        this._frontend.state.result.setValue(resultData)
        this.updateRouteState({
            route: route,
            type: "event",
            title: title
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * TRACK
 	 * --------------------------------------------------------------*/

    private async showTrackRoute(route: string, pushToHistory: boolean) {
        const trackRoute = FrontendRouteUtils.parseTrackRoute(route)
        if (!trackRoute) {
            this.showRoute(FrontendRoute.HOME, pushToHistory)
            return
        }
        this.resetDataStates(route)
        const ownerData = await this.getOwnerData(trackRoute.ownerType, trackRoute.ownerPath)
        const trackData = await this.getTrackState(trackRoute)
        if (!trackData) {
            switch (trackRoute.ownerType) {
                case "user":
                    return this.showUserRoute(FrontendRoute.USER_TRACKS(trackRoute.ownerPath), pushToHistory)
                case "group":
                    return this.showGroupRoute(FrontendRoute.GROUP_TRACKS(trackRoute.ownerPath), pushToHistory)
            }
        }
        this._frontend.state.routeOwner.setValue(ownerData)
        switch (trackRoute.ownerType) {
            case "user":
                this._frontend.state.group.setValue(null)
                break
            case "group":
                this._frontend.state.group.setValue(ownerData)
                break
        }
        this._frontend.state.track.setValue(trackData)
        this.updateRouteState({
            route: route,
            type: "track",
            title: trackData.name + (trackData.layout ? (" (" + trackData.layout + ")") : "")
        }, pushToHistory)
    }

    /* ----------------------------------------------------------------
 	 * UTILS
 	 * --------------------------------------------------------------*/

    private async getRouteUserState(userPath): Promise<IUserData> {
        if (this._frontend.state.routeOwner.getValue()?.path == userPath) {
            return this._frontend.state.routeOwner.getValue()
        }
        return await this._frontend.api.user.getUserDataByPath(userPath)
    }

    private async getRouteGroupState(groupPath): Promise<IGroupData> {
        if (this._frontend.state.group.getValue()?.path == groupPath) {
            return this._frontend.state.group.getValue()
        }
        return await this._frontend.api.group.getGroupDataByPath(groupPath)
    }

    private async getEventState(eventRoute: IFrontendEventRoute): Promise<IEventData> {
        const currentEventState = this._frontend.state.event.getValue()
        if (currentEventState
            && currentEventState.owner.path == eventRoute.ownerPath
            && currentEventState.path == eventRoute.eventPath) {
            return currentEventState
        }
        return await this._frontend.api.event.getEventByRoute(eventRoute.route)
    }

    private async getTrackState(trackRoute): Promise<ITrackData> {
        const currentTrackState = this._frontend.state.track.getValue()
        if (currentTrackState
            && currentTrackState.owner.path == trackRoute.ownerPath
            && currentTrackState.path == trackRoute.trackPath) {
            return currentTrackState
        }
        return await this._frontend.api.track.getByRoute(trackRoute.route)
    }

    private async getOwnerData(ownerType: TrackOwnerType | EventOwnerType, ownerPath: string): Promise<IUserData | IGroupData> {
        switch (ownerType) {
            case "user":
                return await this.getRouteUserState(ownerPath)
            case "group":
                return await this.getRouteGroupState(ownerPath)
        }
    }

    private updateRouteState(frontendRoute: IFrontendRoute, pushToHistory: boolean) {
        if (pushToHistory) {
            history.pushState(frontendRoute, null, FrontendConfig.BASE_URL + frontendRoute.route)
        }
        if (frontendRoute.title) {
            document.title = frontendRoute.title + " | " + this._frontend.dict.get(SharedDictionaryKeys.APP_TITLE_DICT_KEY)
            document.getElementsByTagName('title')[0].innerText = document.title
        }
        this._frontend.socket.send("frontend.route.change", {route: frontendRoute.route})
        this._frontend.state.route.setValue(frontendRoute)
    }

    private resetDataStates(route: string) {
        const routeType = FrontendRouteUtils.getRouteType(route)
        if (routeType == "member") {
            this._frontend.state.group.setValue(null)
        }
        if (routeType == "event") {
            const newEventRoute = FrontendRouteUtils.parseEventRoute(route)
            const oldEventRoute = FrontendRouteUtils.parseEventRoute(this._frontend.state.route.getValue()?.route)
            if (newEventRoute && newEventRoute.eventPath == oldEventRoute?.eventPath) {
                if (newEventRoute.sessionPath != oldEventRoute?.sessionPath) {
                    this._frontend.state.session.setValue(null)
                    this._frontend.state.sessionLeaderboard.setValue(null)
                    this._frontend.state.sessionLogData.setValue(null)
                }
                if (newEventRoute.resultPath != oldEventRoute?.resultPath) {
                    this._frontend.state.result.setValue(null)
                }
            } else {
                this._frontend.state.event.setValue(null)
                this._frontend.state.eventChildren.setValue(null)
                this._frontend.state.session.setValue(null)
                this._frontend.state.sessionLeaderboard.setValue(null)
                this._frontend.state.sessionLogData.setValue(null)
                this._frontend.state.result.setValue(null)
            }
        }
        if (routeType != "event") {
            this._frontend.state.event.setValue(null)
            this._frontend.state.eventChildren.setValue(null)
            this._frontend.state.session.setValue(null)
            this._frontend.state.result.setValue(null)
            this._frontend.state.sessionLeaderboard.setValue(null)
            this._frontend.state.sessionLogData.setValue(null)
        }
        if (routeType != "track") {
            this._frontend.state.track.setValue(null)
        }
    }

    private showModal(route: string) {
        const routeType = "/" + route?.split("/")?.[1]
        switch (routeType) {
            case FrontendRoute.ACADEMY:
                this._frontend.state.showAcademy.setValue(route?.split("/")?.[2] ?? true)
                break
            case FrontendRoute.NEWSLETTER:
                this._frontend.state.showAuthUserSettings.setValue("newsletter")
                break
            case FrontendRoute.PADDLE:
                this._frontend.state.showAuthUserSettings.setValue("subscription")
                break
            case FrontendRoute.IMPRINT:
                this._frontend.state.showArticle.setValue(this._frontend.dict.get("article.id.imprint"))
                break
            case FrontendRoute.PRIVACY:
                this._frontend.state.showArticle.setValue(this._frontend.dict.get("article.id.privacy"))
                break
            case FrontendRoute.TERMSOFUSE:
                this._frontend.state.showArticle.setValue(this._frontend.dict.get("article.id.termsofuse"))
                break
            case FrontendRoute.ABOUT:
                if (!this._frontend.state.authUser.hasValue()) return
                this._frontend.state.showArticle.setValue(this._frontend.dict.get("article.id.homepage"))
                break
            case FrontendRoute.ARTICLE:
                this._frontend.state.showArticle.setValue(route.split("/")?.[2])
                break
        }
    }

    private hideModals() {
        this._frontend.state.showLeaderboardEntry.setValue(null)
        this._frontend.state.showAuthUserSettings.setValue(null)
        this._frontend.state.showFavorites.setValue(false)
        this._frontend.state.showLogout.setValue(false)
        this._frontend.state.showEventSettings.setValue(false)
        this._frontend.state.showSessionSettings.setValue(false)
        this._frontend.state.showGroupPreview.setValue(null)
        this._frontend.state.showGroupsFinder.setValue(false)
        this._frontend.state.showEventsFinder.setValue(false)
        this._frontend.state.showArticle.setValue(null)
    }

    private saveRouteToLocalStorage(route: string) {
        localStorage.setItem("latestRoute", route)
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onPopState() {
        this.showRoute(history.state?.route ?? this.currentFrontendRoute, false)
    }
}
