import * as React from "react";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";
import {SessionStateType} from "../../../../../../../shared/types/SessionStateType";

/******************************************************************
 * SessionResultBadge
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionResultBadge(props: {
    position: number
    sessionPath: string
    sessionState: SessionStateType
    fastestLap: boolean
    discarded: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {router} = useServices()
    const {eventPath, eventOwner, eventOwnerType} = useEventState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function gotoSession() {
        router.showRoute(
            FrontendRoute.EVENT_SESSION(
                eventOwnerType,
                eventOwner?.path,
                eventPath,
                props.sessionPath))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="session-result-badge"
            data-fastest-lap={props.fastestLap}
            data-position={props.position}
            data-clickable={!!props.sessionPath}
            data-discarded={props.discarded}
            data-not-finished={props.sessionState && props.sessionState !== "finished"}
            data-not-participated={!props.position}
            onClick={gotoSession}>
            {props.position ?? "–"}
            {props.discarded && props.position &&
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.388786" y1="0.937859" x2="15.9018" y2="16.4508" stroke="#F50B35"/>
                    <line x1="15.9018" y1="1.64497" x2="0.388787" y2="17.1579" stroke="#F50B35"/>
                </svg>}
        </div>
    );

}
