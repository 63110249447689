import * as React from "react";
import {EventSidebarImage} from "../../../ui/image/event/EventSidebarImage";
import {EventSidebarInfo} from "./info/EventSidebarInfo";
import {EventSidebarMenu} from "./menu/EventSidebarMenu";
import {useEventState} from "../../../hooks/useEventState";
import {useEffect} from "react";
import {useServices} from "../../../hooks/useServices";
import {useGroupState} from "../../../hooks/useGroupState";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useRouteOwner} from "../../../hooks/useRouteOwner";

/******************************************************************
 * EventSidebar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventSidebar() {

    /* ----------------------------------------------------------------
    * HOOKS
    * --------------------------------------------------------------*/

    const {router} = useServices()
    const {eventImage, eventID, eventPrivacy, eventOwnerType} = useEventState();
    const {isGroupAdmin, isGroupMember} = useGroupState()
    const {isRouteOwnerAuthUser} = useRouteOwner()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        redirectToHomeWhenNoRights()
    }, [eventPrivacy])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function redirectToHomeWhenNoRights() {
        switch (eventPrivacy) {
            case "public":
                return
            case "private":
                if (eventOwnerType == "user" && isRouteOwnerAuthUser) return
                if (isGroupMember) return
                router.showRoute(FrontendRoute.HOME)
            case "admin":
                if (eventOwnerType == "user" && isRouteOwnerAuthUser) return
                if (isGroupAdmin) return
                router.showRoute(FrontendRoute.HOME)
                break
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section key={eventID} className="event-sidebar">
            <EventSidebarImage
                enableMediaView={true}
                editable={!eventImage}/>
            <EventSidebarInfo/>
            <EventSidebarMenu/>
        </section>
    );

}
