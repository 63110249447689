import {ITrackFilterData} from "../../shared/models/submodels/ITrackFilterData";
import {TrackType} from "../../shared/types/TrackType";
import {DriverLicenseType} from "../../shared/types/DriverLicenseType";
import {PrivacyType} from "../../shared/types/PrivacyType";
import {IconType} from "../components/ui/icons/IconType";

/******************************************************************
 * TrackUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class TrackUtils {

    static trackFilterName(
        trackFilter: ITrackFilterData,
        trackType: TrackType,
        dict: (key: string) => string
    ): string {
        if (!trackFilter) return ""
        return trackFilter.isPreset
            ? trackFilter.presetType == "default"
                ? trackType == "lap"
                    ? dict("track.filter.besttimes.name")
                    : dict("track.filter.bestscores.name")
                : dict(trackFilter.name)
            : trackFilter.name
    }

    static isHigherDriverLicenseRequired(
        authUserUseSubscription: boolean,
        authUserValidDriverLicense: DriverLicenseType,
        trackFilter: ITrackFilterData
    ): boolean {
        if (!authUserUseSubscription) return false
        if (trackFilter?.isPreset && trackFilter?.presetType == "default") return false
        switch (authUserValidDriverLicense) {
            case "free":
                if (trackFilter?.isPreset && trackFilter?.presetType == "tuning") {
                    return false
                }
                return true
            case "basic":
                return !trackFilter?.isPreset
            case "premium":
                return false
        }
    }

    static hasUserAccess(
        trackPrivacy: PrivacyType,
        isGroupMember: boolean,
        isGroupAdmin: boolean,
        isRouteOwner: boolean
    ): boolean {
        switch (trackPrivacy) {
            case "admin":
                return isGroupAdmin || isRouteOwner
            case "private":
                return isGroupMember || isRouteOwner
            case "public":
                return true
        }
    }

    static trackPrivacyIconType(trackPrivacy: PrivacyType): IconType {
        switch (trackPrivacy) {
            case "admin":
                return "hidden"
            default:
                return trackPrivacy as IconType
        }
    }

    static trackNotAvailableDictKey(trackPricacy: PrivacyType, isTraining: boolean): string {
        if (isTraining) return "track.privacy.no.access.training"
        return trackPricacy == "admin"
            ? "track.privacy.no.access.admin"
            : "track.privacy.no.access.private"
    }

}
