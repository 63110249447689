import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useServices} from "../../../../hooks/useServices";
import {IGroupData} from "../../../../../../shared/models/IGroupData";
import {Table} from "../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {Headline} from "../../../../ui/text/headings/Headline";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {Icon} from "../../../../ui/icons/Icon";
import {FavoriteButton} from "../../../../ui/buttons/favorite/FavoriteButton";
import {useSocketSignals} from "../../../../hooks/useSocketSignals";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {BasicPageTitle} from "../../../../ui/text/headings/BasicPageTitle";

/******************************************************************
 * UserGroupsPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserGroupsPage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api, router} = useServices()
    const {isRouteOwnerAuthUser, routeOwnerID, routeOwnerNick, routeOwnerName} = useRouteOwner()
    const {authUserID} = useAuthUser()
    const {serverGroupUpdate} = useSocketSignals()
    const [isMobileMode] = useMobileStyle(rootRef, 700)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [loading, setLoading] = useState<boolean>(true)
    const [ownGroups, setOwnGroups] = useState<IGroupData[]>()
    const [memberGroups, setMemberGroups] = useState<IGroupData[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setLoading(true)
        loadUserGroups()
    }, [serverGroupUpdate])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadUserGroups() {
        const groups = await api.group.getUserGroups(routeOwnerID)
        setOwnGroups(groups?.filter(group => group.owner._id === routeOwnerID))
        setMemberGroups(groups?.filter(group => group.owner._id !== routeOwnerID))
        setLoading(false)
    }

    function columnsDefinition(): ITableColumnDefinition<IGroupData>[] {
        return [{
            type: "custom",
            size: "30px",
            customCell: (key, data) => {
                return <ContentLayout key={key} justifyItems="center">
                    {data.type === "online"
                        ? <Icon type="online" scale={0.8}/>
                        : <Icon type="location" scale={0.5}/>}
                </ContentLayout>
            }
        }, {
            type: "image-circle",
            dataKey: "avatar",
            size: "50px"
        }, {
            dataKey: "name",
            type: "text-bold",
        }, {
            type: "privacy",
            dataKey: "privacy",
            size: "15px"
        }, {
            type: "custom",
            size: authUserID ? "40px" : null,
            customCell: (key, data) => {
                return <ContentLayout key={key} justifyItems="center">
                    <FavoriteButton favoriteID={data._id} type={"group"}/>
                </ContentLayout>
            }
        }]
    }

    function gotoGroup(data: IGroupData) {
        router.showRoute(FrontendRoute.GROUP(data.path))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!loading}>
            <ContentLayout alignContent="start">
                <BasicPageTitle
                    title={dict("user.menu.groups")}
                    subTitle={routeOwnerNick ?? routeOwnerName}/>
                <ContentLayout
                    ref={rootRef}
                    className="user-groups-page"
                    alignItems="start"
                    alignContent="start"
                    columns={isMobileMode ? 1 : 2}>
                    <ContentLayout framed={true}>
                        <ContentLayout
                            columnTemplate={isRouteOwnerAuthUser ? "auto min-content" : null}
                            justifyContent="space-between">
                            <Headline
                                text={dict("user.groups.own.title")}
                                style="h5-underlined"/>
                            {isRouteOwnerAuthUser && <LabelButton
                                label={dict("group.create.button.label")}
                                style={"primary-small"}
                                onClick={() => state.showCreateGroup.setValue(true)}/>}
                        </ContentLayout>
                        <Table<IGroupData>
                            rowsData={ownGroups}
                            showHeader={false}
                            showFilter={ownGroups?.length > 3}
                            noDataInfo={dict(isRouteOwnerAuthUser
                                ? "user.groups.own.noDataInfo"
                                : "user.groups.own.noDataInfo.guest").replaceAll("{NICK}", routeOwnerNick)}
                            columnsDefinition={columnsDefinition()}
                            onRowClick={gotoGroup}/>
                    </ContentLayout>
                    <ContentLayout framed={true}>
                        <ContentLayout
                            columnTemplate={isRouteOwnerAuthUser ? "auto min-content" : null}
                            justifyContent="space-between">
                            <Headline
                                text={dict("user.groups.member.title")}
                                style="h5-underlined"/>
                            {isRouteOwnerAuthUser && <LabelButton
                                label={dict("group.request.membership.button.label")}
                                style={"primary-small"}
                                onClick={() => state.showRequestGroupMembership.setValue(true)}/>}
                        </ContentLayout>
                        <Table<IGroupData>
                            rowsData={memberGroups}
                            showHeader={false}
                            showFilter={memberGroups?.length > 3}
                            noDataInfo={dict(isRouteOwnerAuthUser
                                ? "user.groups.member.noDataInfo"
                                : "user.groups.member.noDataInfo.guest").replaceAll("{NICK}", routeOwnerNick)}
                            columnsDefinition={columnsDefinition()}
                            onRowClick={gotoGroup}/>
                    </ContentLayout>
                </ContentLayout>
            </ContentLayout>
            {isRouteOwnerAuthUser &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("group.create.button.label")}
                        icon="plus"
                        onClick={() => state.showCreateGroup.setValue(true)}
                        style={"action-main"}/>
                    <Divider style={"action-bar"}/>
                    <IconButton
                        type="academy"
                        size="small"
                        onClick={() => state.showAcademy.setValue(dict("academy.id.groups"))}/>
                    <ActionBarSpacer/>
                </ActionBar>}
        </ComponentInitializer>
    );

}
