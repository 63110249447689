import * as React from "react";
import {StateInfo} from "../../../../../../ui/info/state/StateInfo";
import {ContentLayout} from "../../../../../../ui/layout/content/ContentLayout";
import {StintSpeakerContextMenu} from "../../../../../../ui/context/speaker/StintSpeakerContextMenu";
import {StintContextMenu} from "../../../../../../ui/context/stint/StintContextMenu";
import {IStintData} from "../../../../../../../../shared/models/IStintData";
import {useAuthUser} from "../../../../../../hooks/useAuthUser";
import {useRouteOwner} from "../../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../../hooks/useGroupState";
import {FrontendConfig} from "../../../../../../../core/FrontendConfig";
import {useSessionState} from "../../../../../../hooks/useSessionState";

/******************************************************************
 * StintMetaMetrics
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StintMetaMetrics(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {authUserID} = useAuthUser()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {sessionIsFinished} = useSessionState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function canEdit(): boolean {
        return isRouteOwnerAuthUser
            || isGroupAdmin
            || props.stint.user._id === authUserID
    }

    function showStintSpeaker() {
        if (sessionIsFinished) return false
        return FrontendConfig.ENABLE_STINT_SPEAKER
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            framed={true}
            gap="small"
            columns={2}
            alignItems="center"
            justifyContent="space-between"
            columnTemplate="min-content min-content">
            <StateInfo
                state={props.stint.state}/>
            <ContentLayout
                alignItems="center"
                columnTemplate={canEdit() && showStintSpeaker()
                    ? "auto 25px"
                    : null}
                gap="none">
                {showStintSpeaker() &&
                    <StintSpeakerContextMenu
                        stint={props.stint}/>}
                {canEdit() &&
                    <StintContextMenu
                        stint={props.stint}
                        showReset={true}
                        iconSize="small"/>}
            </ContentLayout>
        </ContentLayout>
    );

}
