import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {useGroupState} from "../../../../hooks/useGroupState";
import {OwnerEventsList} from "../../../../ui/list/events/OwnerEventsList";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {GroupUtils} from "../../../../../../shared/utils/GroupUtils";
import {useAuthUser} from "../../../../hooks/useAuthUser";

/******************************************************************
 * GroupEventsPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupEventsPage() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {authUserData} = useAuthUser()
    const {isGroupAdmin, groupData} = useGroupState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showAdminActionBar(): boolean {
        return isGroupAdmin
    }

    function showContactActionBar(): boolean {
        if (showAdminActionBar()) return false
        return GroupUtils.showContactButton(groupData, authUserData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="group-events-page">
            <OwnerEventsList/>
            {showAdminActionBar() &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("event.create")}
                        icon="plus"
                        onClick={() => state.showCreateEvent.setValue(true)}
                        style={"action-main"}/>
                    <Divider style="action-bar"/>
                    <IconButton
                        type="academy"
                        size="small"
                        onClick={() => state.showAcademy.setValue(dict("academy.id.events"))}/>
                    <ActionBarSpacer/>
                </ActionBar>}
            {showContactActionBar() &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("group.contact.label")}
                        style={"action-main"}
                        onClick={() => state.showContact.setValue({
                            sendMessageTo: groupData,
                            messageType: "group"
                        })}/>
                </ActionBar>}
        </ContentLayout>
    );

}
