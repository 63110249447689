import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {PrivacyType} from "../../../../../shared/types/PrivacyType";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {useGroupState} from "../../../hooks/useGroupState";
import {IEventData} from "../../../../../shared/models/IEventData";
import {IconType} from "../../icons/IconType";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";

/******************************************************************
 * PrivacySelector
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PrivacySelector(props: {
    eventData?: IEventData,
    onChange?: (privacy: PrivacyType) => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {routeType} = useRouteStates()
    const {groupID, groupPrivacy} = useGroupState();
    const {eventID, eventPrivacy} = useEventState();
    const {isRouteOwnerAuthUser, isRouteOwnerUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function changePrivacy(privacy: PrivacyType) {
        if (props.eventData) {
            await api.event.update(props.eventData._id, {privacy: privacy});
        } else {
            switch (routeType) {
                case "event":
                    await api.event.update(eventID, {privacy: privacy});
                    break;
                case "group":
                    await api.group.update(groupID, {privacy: privacy});
                    break;
            }
        }
        props.onChange?.(privacy)
    }

    function privacyType(): PrivacyType {
        if (props.eventData) {
            return props.eventData.privacy
        }
        switch (routeType) {
            case "event":
                return eventPrivacy
            case "group":
                return groupPrivacy
        }
        return null
    }

    function iconType(): IconType {
        const pricyType = privacyType()
        switch (pricyType) {
            case "admin":
                return "hidden"
            default:
                return pricyType as IconType
        }
    }

    function showAdminStateSelector(): boolean {
        if (isRouteOwnerUser) {
            return false
        }
        return routeType == "event" || !!props.eventData
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType={iconType()}
            iconScale={0.85}
            iconSize="small"
            stopPropagation={true}
            className="privacy-selector"
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            <ContentLayout
                useFrameMargin={true}
                gap="small"
                alignItems="center">
                <Headline text={dict("event.privacy.name")} style="h6"/>
            </ContentLayout>
            <ContextMenuSelectable
                icon="public"
                label={dict("event.privacy.public")}
                onClick={() => changePrivacy("public")}
                selected={privacyType() == "public"}/>
            <ContextMenuDivider style="small"/>
            <ContextMenuSelectable
                icon="private"
                label={isRouteOwnerUser ? dict("training.privacy.private") : dict("event.privacy.private")}
                onClick={() => changePrivacy("private")}
                selected={privacyType() == "private"}/>
            {showAdminStateSelector() && <>
                <ContextMenuDivider style="small"/>
                <ContextMenuSelectable
                    icon="hidden"
                    label={dict("event.privacy.admin")}
                    onClick={() => changePrivacy("admin")}
                    selected={privacyType() == "admin"}/>
            </>}
        </ContextMenu>
    );

}
