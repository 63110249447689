import * as React from "react"
import {useEffect, useRef, useState} from "react"
import {ContentLayout} from "../../../ui/layout/content/ContentLayout"
import {useServices} from "../../../hooks/useServices"
import {ActionBar} from "../../../ui/bar/ActionBar"
import {LabelButton} from "../../../ui/buttons/label/LabelButton"
import {useRouteOwner} from "../../../hooks/useRouteOwner"
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData"
import {TrackTeaser} from "../../../ui/teaser/track/TrackTeaser"
import {useSocketSignals} from "../../../hooks/useSocketSignals"
import {useRouteStates} from "../../../hooks/useRouteStates"
import {useGroupState} from "../../../hooks/useGroupState"
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {EmptyDataInfo} from "../../../ui/info/empty/EmptyDataInfo";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {Headline} from "../../../ui/text/headings/Headline";
import {Divider} from "../../../ui/utils/divider/Divider";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {ActionBarSpacer} from "../../../ui/bar/spacer/ActionBarSpacer";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessMaxType} from "../../../../../shared/types/PlanAccessType";
import {GroupUtils} from "../../../../../shared/utils/GroupUtils";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {BasicPageTitle} from "../../../ui/text/headings/BasicPageTitle";
import {TabBar} from "../../../ui/layout/tabbed/TabBar";
import {TabBarItem} from "../../../ui/layout/tabbed/TabBarItem";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * TracksPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

type SelectFilterType = "public" | "private" | "admin" | "archive"

export function TracksPage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices()
    const {routeType} = useRouteStates()
    const {
        routeOwnerID,
        isRouteOwnerAuthUser,
        routeOwnerValidDriverLicense,
        routeOwnerUseSubscription
    } = useRouteOwner()
    const {authUserData} = useAuthUser()
    const {groupID, isGroupAdmin, groupData} = useGroupState()
    const {serverTrackUpdate} = useSocketSignals()
    const [isMobile] = useMobileStyle(ref, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [activeTracks, setActiveTracks] = useState<ITrackData[]>()
    const [inactiveTracks, setInactiveTracks] = useState<ITrackData[]>()
    const [filteredActiveTracks, setFilteredActiveTracks] = useState<ITrackData[]>()
    const [columnData, setColumnData] = useState<ITrackData[][]>([]);
    const [numColumns, setNumColumns] = useState<number>(computeNumColumns())
    const [loading, setLoading] = useState<boolean>(true)
    const [maxTracksAllowed, setMaxTracksAllowed] = useState<number>()
    const [selectedFilter, setSelectedFilter] = useState<SelectFilterType>("public")

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadOwnerTracks()
    }, [routeOwnerID, groupID, serverTrackUpdate, routeOwnerValidDriverLicense, routeOwnerUseSubscription])

    useEffect(() => {
        setNumColumns(computeNumColumns())
    }, [isMobile])

    useEffect(() => {
        const columns = Array.from({length: numColumns}, () => []);
        filteredActiveTracks?.sort((a, b) => {
            if (!a._id) return -1
            if (!b._id) return 1
            return b._id.localeCompare(a._id)
        })
        filteredActiveTracks?.forEach((track: ITrackData, i: number) => {
            const columnIndex = i % numColumns;
            columns[columnIndex].push(track);
        });
        setColumnData(columns);
    }, [numColumns, filteredActiveTracks]);

    useEffect(() => {
        const availableFilters: SelectFilterType[] = ["public", "private", "admin", "archive"]
        let faTracks = getFilteredTracks(selectedFilter)
        if (faTracks?.length == 0 && activeTracks?.length > 0) {
            for (let i = 0; i < availableFilters.length; i++) {
                const filter = availableFilters[i]
                faTracks = getFilteredTracks(filter)
                if (faTracks?.length > 0) {
                    setSelectedFilter(filter)
                    return
                }
            }
        }

        function getFilteredTracks(filter: SelectFilterType) {
            return activeTracks?.filter(track => {
                if (filter == "archive" && track.isArchived) return true
                if (filter == "public" && track.privacy == "public" && !track.isArchived) return true
                if (filter == "private" && track.privacy == "private" && !track.isArchived) return true
                if (filter == "admin" && track.privacy == "admin" && !track.isArchived) return true
                return false
            })
        }

        setFilteredActiveTracks(faTracks)
    }, [activeTracks, inactiveTracks, selectedFilter])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadOwnerTracks() {
        setLoading(true)
        let tracks: ITrackData[]
        switch (routeType) {
            case "member":
                tracks = await api.track.ownerTracks(routeOwnerID, "user")
                break
            case "group":
                tracks = await api.track.ownerTracks(groupID, "group")
                break
        }
        setActiveTracks(tracks?.filter(track => track._notAllowedToUse != true))
        setInactiveTracks(tracks?.filter(track => track._notAllowedToUse == true))
        setMaxTracksAllowed(computeMaxTracksAllowed())
        setLoading(false)
    }

    function computeNumColumns(inactiveTracks?: boolean) {
        return isMobile ? (inactiveTracks ? 2 : 1) : (inactiveTracks ? 3 : 2)
    }

    function computeMaxTracksAllowed() {
        switch (routeType) {
            case "member":
                if (!routeOwnerUseSubscription) return undefined
                return PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("tracks", routeOwnerValidDriverLicense)?.value
            case "group":
                const subscriptionUser = GroupUtils.getMemberWithHighestSubscription(groupData)
                if (!subscriptionUser) return undefined
                if (FrontendConfig.PADDLE_ENVIRONMENT == "sandbox"
                    && GroupUtils.hasMembersWithNoSubscription(groupData)) {
                    return undefined
                }
                const validDriverLicense = DriverLicenseUtils.validLicenseType(subscriptionUser?.subscription)
                return PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("tracks", validDriverLicense)?.value
        }
        return null
    }

    function isOwner() {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    function inactiveTracksTitle(): string {
        if (!inactiveTracks || inactiveTracks?.length == 0) {
            return dict("tracks.max.reached")
        }
        if (inactiveTracks?.length == 1) {
            return dict("track.inactive.single.title")
        }
        switch (routeType) {
            case "member":
                return dict("track.inactive.multiple.title")
                    .replaceAll("{NUM_INACTIVE_TRACKS}", inactiveTracks?.length.toString())
            case "group":
                return dict("track.inactive.multiple.group.title")
                    .replaceAll("{NUM_INACTIVE_TRACKS}", inactiveTracks?.length.toString())
        }
        return ""
    }

    function numActivePublicTracks(): number {
        return (activeTracks?.filter(track => {
            return track.privacy == "public" && !track.isArchived
        }).length ?? 0)
    }

    function numActivePrivateTracks(): number {
        return (activeTracks?.filter(track => {
            return track.privacy == "private" && !track.isArchived
        }).length ?? 0)
    }

    function numActiveAdminTracks(): number {
        return (activeTracks?.filter(track => {
            return track.privacy == "admin" && !track.isArchived
        }).length ?? 0)
    }

    function numActiveArchivedTracks(): number {
        return (activeTracks?.filter(track => {
            return track.isArchived
        }).length ?? 0)
    }

    function hasNonPublicTracks(): boolean {
        return numActivePrivateTracks() > 0 || numActiveArchivedTracks() > 0 || numActiveAdminTracks() > 0
    }

    function showGroupContactButton(): boolean {
        if (routeType != "group") return false
        return GroupUtils.showContactButton(groupData, authUserData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!loading} className="tracks-page">
            <ContentLayout
                ref={ref}
                alignContent={filteredActiveTracks?.length > 0 ? "start" : "stretch"}
                alignItems={filteredActiveTracks?.length > 0 ? "start" : "center"}>
                {filteredActiveTracks?.length > 0 && <>
                    <BasicPageTitle
                        title={dict("user.menu.tracks")}
                        subContent={hasNonPublicTracks() &&
                            <TabBar align="center">
                                {numActivePublicTracks() > 0 &&
                                    <TabBarItem
                                        name={dict("tracks.filter.public.label")}
                                        selected={selectedFilter == "public"}
                                        onClick={() => setSelectedFilter("public")}/>}
                                {numActivePrivateTracks() > 0 &&
                                    <TabBarItem
                                        name={dict("tracks.filter.private.label")}
                                        selected={selectedFilter == "private"}
                                        onClick={() => setSelectedFilter("private")}/>}
                                {numActiveAdminTracks() > 0 &&
                                    <TabBarItem
                                        name={dict("tracks.filter.admin.label")}
                                        selected={selectedFilter == "admin"}
                                        onClick={() => setSelectedFilter("admin")}/>}
                                {numActiveArchivedTracks() > 0 &&
                                    <TabBarItem
                                        name={dict("tracks.filter.archive.label")}
                                        selected={selectedFilter == "archive"}
                                        onClick={() => setSelectedFilter("archive")}/>}
                            </TabBar>}/>
                </>}
                {filteredActiveTracks?.length > 0
                    ? <ContentLayout>
                        <ContentLayout columns={numColumns} gap="large">
                            {columnData?.map((column: ITrackData[], index) => (
                                <ContentLayout
                                    key={index}
                                    gap="medium-to-large">
                                    {column.map(trackData =>
                                        <TrackTeaser
                                            key={trackData._id}
                                            data={trackData}/>)}
                                </ContentLayout>
                            ))}
                        </ContentLayout>
                        {!!maxTracksAllowed && activeTracks?.length >= maxTracksAllowed && <>
                            <Spacer height="normal"/>
                            <ContentLayout framed={isOwner()}>
                                {isOwner() && <Spacer height="small"/>}
                                <Headline
                                    text={inactiveTracksTitle()}
                                    align="center"
                                    style={isOwner() ? "h3" : "h5"}/>
                                {isOwner() &&
                                    <ContentLayout justifyItems={"center"}>
                                        <InfoText
                                            text={dict("subscription.upgrade.info.tracks")}
                                            align="center"/>
                                        <LabelButton
                                            label={dict("subscription.upgrade.info.button.showPlans")}
                                            style="primary-small"
                                            onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                                    </ContentLayout>}
                                {isOwner() &&
                                    <ContentLayout
                                        columns={computeNumColumns(true)}
                                        gap="medium">
                                        {inactiveTracks?.map((trackData) => {
                                            return <TrackTeaser
                                                key={trackData._id}
                                                inactive={true}
                                                data={trackData}/>
                                        })}
                                    </ContentLayout>}
                            </ContentLayout>
                        </>}
                    </ContentLayout>
                    : <ContentLayout gap="small" justifyItems="center">
                        <EmptyDataInfo text={isOwner()
                            ? dict("track.owner.empty.info")
                            : dict("track.guest.empty.info")}/>
                        <LabelButton
                            label={dict("track.empty.academy.button.label")}
                            style="secondary"
                            icon="academy"
                            onClick={() => state.showAcademy.setValue(dict("academy.id.tracks"))}/>
                    </ContentLayout>}
            </ContentLayout>
            {isOwner() &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("track.create.button.label")}
                        icon="plus"
                        onClick={() => state.showCreateTrack.setValue(true)}
                        style={"action-main"}/>
                    <Divider style="action-bar"/>
                    <IconButton
                        type="academy"
                        size="small"
                        onClick={() => state.showAcademy.setValue(dict("academy.id.tracks"))}/>
                    <ActionBarSpacer/>
                </ActionBar>}
            {showGroupContactButton() && !isOwner() &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("group.contact.label")}
                        style={"action-main"}
                        onClick={() => state.showContact.setValue({
                            sendMessageTo: groupData,
                            messageType: "group"
                        })}/>
                </ActionBar>}
        </ComponentInitializer>
    )
}
