import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {ITrackData} from "../../shared/models/submodels/ITrackData";
import {TrackOwnerType} from "../../shared/types/TrackOwnerType";
import {ITrackFilterPreview} from "../../shared/types/ITrackFilterPreview";
import {IEventData} from "../../shared/models/IEventData";

/******************************************************************
 * TrackAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class TrackAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async create(data: {
        name: string,
        layout: string,
        ownerID: MongoObjectIDType
        ownerType: TrackOwnerType
    }): Promise<Response> {
        if (!data) return null;
        return await this._frontend.api.request("POST", APIRoute.TRACK, data, true);
    }

    public async uploadImage(formData): Promise<Response> {
        if (!formData) return null;
        return await this._frontend.api.upload(APIRoute.TRACK_IMAGE, formData);
    }

    public async uploadInstructionImage(formData): Promise<Response> {
        if (!formData) return null;
        return await this._frontend.api.upload(APIRoute.TRACK_INSTRUCTION_IMAGE, formData);
    }

    public async deleteInstructionImage(trackID: MongoObjectIDType): Promise<Response> {
        if (!trackID) return null;
        return await this._frontend.api.request("DELETE", APIRoute.TRACK_INSTRUCTION_IMAGE, {trackID: trackID}, true);
    }

    public async update(trackID: MongoObjectIDType, data: ITrackData): Promise<Response> {
        if (!trackID) return null;
        return await this._frontend.api.request("PATCH", APIRoute.TRACK, {trackID: trackID, ...data}, true);
    }

    public async getTrackFilterPreviews(trackID: MongoObjectIDType): Promise<ITrackFilterPreview[]> {
        if (!trackID) return null;
        const response = await this._frontend.api.request("GET", APIRoute.TRACK_FILTER_PREVIEW, {trackID}, true);
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async ownerTracks(ownerID: MongoObjectIDType, ownerType: TrackOwnerType, skipArchivedTracks?: boolean): Promise<ITrackData[]> {
        if (!ownerID) return null;
        if (!ownerType) return null;
        const response = await this._frontend.api.request("GET", APIRoute.TRACKS, {
            ownerID: ownerID,
            ownerType: ownerType,
            skipArchivedTracks: skipArchivedTracks
        }, true)
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    public async getEventsUsingTrack(trackID: MongoObjectIDType): Promise<IEventData[]> {
        if (!trackID) return null;
        const response = await this._frontend.api.request("GET", APIRoute.TRACK_USED, {trackID}, true)
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    public async getByRoute(route: string): Promise<ITrackData> {
        if (!route) return null;
        const response = await this._frontend.api.request("GET", APIRoute.TRACK, {trackRoute: route}, true);
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async canCreateTrack(): Promise<boolean> {
        const response = await this._frontend.api.request("GET", APIRoute.TRACK_CAN_CREATE, {}, true)
        if (response.status == 200) {
            return true
        }
        return false
    }

    public async delete(trackID: MongoObjectIDType): Promise<Response> {
        if (!trackID) return null
        return await this._frontend.api.request("DELETE", APIRoute.TRACK, {trackID: trackID}, true)
    }
}
