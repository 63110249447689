import {useEffect, useState} from "react";
import {PrivacyType} from "../../../shared/types/PrivacyType";
import {IUserData} from "../../../shared/models/IUserData";
import {useServices} from "./useServices";
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";
import {ITrackData} from "../../../shared/models/submodels/ITrackData";
import {IFileData} from "../../../shared/models/IFileData";
import {ITrackFilterData} from "../../../shared/models/submodels/ITrackFilterData";
import {TrackOwnerType} from "../../../shared/types/TrackOwnerType";
import {IGroupData} from "../../../shared/models/IGroupData";
import {TrackType} from "../../../shared/types/TrackType";
import {DriftTargetCodeType} from "../../../shared/types/DriftTargetCodeType";
import {TrackLapCountModeType} from "../../../shared/types/TrackLapCountModeType";
import {DriftTrackBundleType} from "../../../shared/types/DriftTrackBundleType";
import {TargetPreProcessorMode} from "../../../shared/types/TargetPreProcessorMode";

/******************************************************************
 * TrackState Hook
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useTrackState() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [trackData, setTrackData] = useState<ITrackData>(state.track.getValue());
    const [trackID, setTrackID] = useState<MongoObjectIDType>(state.track.getValue()?._id);
    const [trackName, setTrackName] = useState<string>(state.track.getValue()?.name);
    const [trackLayout, setTrackLayout] = useState<string>(state.track.getValue()?.layout);
    const [trackPath, setTrackPath] = useState<string>(state.track.getValue()?.path);
    const [trackPrivacy, setTrackPrivacy] = useState<PrivacyType>(state.track.getValue()?.privacy);
    const [trackIsArchived, setTrackIsArchived] = useState<boolean>(state.track.getValue()?.isArchived);
    const [trackOwner, setTrackOwner] = useState<IUserData | IGroupData>(state.track.getValue()?.owner);
    const [trackOwnerType, setTrackOwnerType] = useState<TrackOwnerType>(state.track.getValue()?.ownerType);
    const [trackImage, setTrackImage] = useState<IFileData>(state.track.getValue()?.image);
    const [trackInstructionImage, setTrackInstructionImage] = useState<IFileData>(state.track.getValue()?.instructionImage);
    const [trackInstructionInfo, setTrackInstructionInfo] = useState<string>(state.track.getValue()?.instructionInfo);
    const [trackFilters, setTrackFilters] = useState<ITrackFilterData[]>(state.track.getValue()?.filters);
    const [trackType, setTrackType] = useState<TrackType>(state.track.getValue()?.type);
    const [trackMinLapTime, setTrackMinLapTime] = useState<number>(state.track.getValue()?.minLapTime);
    const [trackNumSectors, setTrackNumSectors] = useState<number>(state.track.getValue()?.numSectors);
    const [trackSectorTargets, setTrackSectorTargets] = useState<DriftTargetCodeType[]>(state.track.getValue()?.sectorTargets)
    const [trackLapCountMode, setTrackLapCountMode] = useState<TrackLapCountModeType>(state.track.getValue()?.lapCountMode);
    const [trackBundle, setTrackBundle] = useState<DriftTrackBundleType>(state.track.getValue()?.trackBundle);
    const [trackJokerTarget, setTrackJokerTarget] = useState<DriftTargetCodeType | "none">(state.track.getValue()?.jokerLapTarget);
    const [trackTargetPreProcessor, setTrackTargetPreProcessor] = useState<TargetPreProcessorMode>(state.track.getValue()?.targetPreProcessor);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateTrackStates();
        state.track.onChangeSignal.add(updateTrackStates);
        return () => {
            state.track.onChangeSignal.remove(updateTrackStates);
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateTrackStates() {
        const track: ITrackData = state.track.getValue()
        setTrackData(track)
        setTrackID(track?._id)
        setTrackOwnerType(track?.ownerType)
        setTrackName(track?.name)
        setTrackLayout(track?.layout)
        setTrackPath(track?.path)
        setTrackPrivacy(track?.privacy)
        setTrackIsArchived(track?.isArchived)
        setTrackOwner(track?.owner)
        setTrackImage(track?.image)
        setTrackInstructionImage(track?.instructionImage)
        setTrackInstructionInfo(track?.instructionInfo)
        setTrackMinLapTime(track?.minLapTime)
        setTrackType(track?.type)
        setTrackNumSectors(track?.numSectors)
        setTrackSectorTargets(track?.sectorTargets)
        setTrackLapCountMode(track?.lapCountMode)
        setTrackBundle(track?.trackBundle)
        setTrackJokerTarget(track?.jokerLapTarget)
        setTrackTargetPreProcessor(track?.targetPreProcessor)
        setTrackFilters(track?.filters)
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        trackData,
        trackID,
        trackName,
        trackPath,
        trackOwner,
        trackOwnerType,
        trackPrivacy,
        trackIsArchived,
        trackImage,
        trackInstructionImage,
        trackInstructionInfo,
        trackLayout,
        trackFilters,
        trackMinLapTime,
        trackType,
        trackNumSectors,
        trackSectorTargets,
        trackLapCountMode,
        trackBundle,
        trackJokerTarget,
        trackTargetPreProcessor
    };
}
