import * as React from "react";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {Icon} from "../../icons/Icon";
import {Picture} from "../../image/Picture";
import {IconType} from "../../icons/IconType";

/******************************************************************
 * TrackTeaser
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackTeaser(props: {
    data: ITrackData
    inactive?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showTrackPage() {
        if (!props.data) return
        router.showRoute(FrontendRoute.TRACK(props.data.ownerType, props.data.owner.path, props.data.path))
    }

    function showStateIcon() {
        if (props.data.isArchived) {
            return true
        }
        switch (props.data.privacy) {
            case "private":
            case "admin":
                return true
            default:
                return false
        }
    }

    function stateIconType(): IconType {
        if (props.data.isArchived) {
            return "archive";
        }
        switch (props.data.privacy) {
            case "admin":
                return "hidden"
            default:
                return props.data.privacy as IconType
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <figure
            className="track-teaser"
            data-inactive={props.inactive}
            onClick={showTrackPage}>
            <Picture file={props.data?.image} alt={props.data?.name}/>
            <div className="track-teaser-name">
                <h2>{props.data?.name}</h2>
                {props.data?.layout && <h3>{props.data?.layout}</h3>}
            </div>
            {showStateIcon() &&
                <div className="track-teaser-state-icon">
                    <Icon
                        type={stateIconType()}
                        scale={props.data.isArchived ? 0.6 : 0.7}/>
                </div>}
        </figure>
    );

}
