/******************************************************************
 * UserPermissionType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

// HINT: use UserUtils.availableUserPermissions to get all available permissions for a user in a specfic environment

export const UserPermissionTypes = [
    "article:update",
    "help:update",
    "api:translate",
    "subscription:skip",
    "subscription:production.force",
    "subscription:sandbox.tester",
    "session:skipSubscriptionCheck"
] as const;

export type UserPermissionType = typeof UserPermissionTypes[number];
