import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useServices} from "../../../hooks/useServices";
import {PrivacyType} from "../../../../../shared/types/PrivacyType";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {IconType} from "../../icons/IconType";
import {LabelButton} from "../../buttons/label/LabelButton";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";

/******************************************************************
 * TrackStateSelector
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackStateSelector(props: {
    trackData: ITrackData
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {isRouteOwnerAuthUser, isRouteOwnerUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function changePrivacy(privacy: PrivacyType) {
        await api.track.update(props.trackData._id, {privacy: privacy});
    }

    async function toggleArchive() {
        await api.track.update(props.trackData._id, {isArchived: !props.trackData.isArchived});
    }

    function trackPrivacy(): PrivacyType {
        return props.trackData?.privacy;
    }

    function stateIcon(): IconType {
        if (props.trackData?.isArchived) {
            return "archive";
        }
        switch (trackPrivacy()) {
            case "admin":
                return "hidden"
            default:
                return trackPrivacy() as IconType
        }
    }

    function stateIconScale(): number {
        return props.trackData?.isArchived ? 0.8 : 0.85;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType={stateIcon()}
            iconScale={stateIconScale()}
            iconSize="small"
            stopPropagation={true}
            className="privacy-selector"
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            {!props.trackData?.isArchived && <>
                <ContentLayout
                    useFrameMargin={true}
                    gap="small"
                    alignItems="center">
                    <Headline text={dict("event.privacy.name")} style="h6"/>
                </ContentLayout>
                <ContextMenuSelectable
                    icon="public"
                    label={dict("event.privacy.public")}
                    onClick={() => changePrivacy("public")}
                    selected={trackPrivacy() == "public"}/>
                <ContextMenuDivider style="small"/>
                <ContextMenuSelectable
                    icon="private"
                    label={isRouteOwnerUser
                        ? dict("training.privacy.private")
                        : dict("event.privacy.private")}
                    onClick={() => changePrivacy("private")}
                    selected={trackPrivacy() == "private"}/>
                {!isRouteOwnerUser && <>
                    <ContextMenuDivider style="small"/>
                    <ContextMenuSelectable
                        icon="hidden"
                        label={dict("event.privacy.admin")}
                        onClick={() => changePrivacy("admin")}
                        selected={trackPrivacy() == "admin"}/>
                </>}
                <ContextMenuDivider style="small"/>
            </>}
            <ContentLayout useFrameMargin={true} gap="small">
                <LabelButton
                    label={props.trackData?.isArchived
                        ? dict("track.unarchive.label")
                        : dict("track.archive.label")}
                    style={"primary-small"}
                    onClick={toggleArchive}/>
            </ContentLayout>
        </ContextMenu>
    );

}
