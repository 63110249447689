import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {Input} from "../../../ui/form/elements/input/Input";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {IResultData} from "../../../../../shared/models/IResultData";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {MultiSelectOptionsList} from "../../../ui/form/elements/select/list/MultiSelectOptionsList";
import {MultiSelectOptionType} from "../../../ui/form/elements/select/options/MultiSelectOption";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {ISessionData} from "../../../../../shared/models/ISessionData";
import {FormMessage} from "../../../ui/form/elements/message/FormMessage";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessType} from "../../../../../shared/types/PlanAccessType";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {Spacer} from "../../../ui/utils/spacer/Spacer";

/******************************************************************
 * ResultSetupModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ResultSetupModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state, router, error} = useServices();
    const {authUserSubscription, authUserUseSubscription} = useAuthUser()
    const {eventID, eventPath, eventOwnerType, eventOwner, eventChildrenOrder} = useEventState()
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [resultData, setResultData] = useState<IResultData>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [busy, setBusy] = useState<boolean>(false)
    const [mode, setMode] = useState<"create" | "edit">("create")
    const [name, setName] = useState<string>()
    const [discardedSessions, setDiscardedSessions] = useState<number>()
    const [selectedSessionIDs, setSelectedSessionIDs] = useState<MongoObjectIDType[]>()
    const [availableSessions, setAvailableSessions] = useState<MultiSelectOptionType[]>([])
    const [nameValid, setNameValid] = useState<boolean>(false)
    const [discardedSessionsValid, setDiscardedSessionsValid] = useState<boolean>(true)
    const [initComplete, setInitComplete] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        switch (typeof state.showResultSetup.getValue()) {
            case "object":
                const data = state.showResultSetup.getValue() as IResultData
                setResultData(data)
                setMode("edit")
                setName(data?.name)
                setDiscardedSessions(data?.discardedSessions)
                setSelectedSessionIDs(data?.sessions.map((session: ISessionData) => session._id))
                break
            case "boolean":
                setResultData(undefined)
                setMode("create")
                setDiscardedSessions(0)
                const validLicenseType = DriverLicenseUtils.validLicenseType(authUserSubscription)
                const isAllowed = PlanFeatureDefinitions.getFeatureAccess<PlanAccessType>("event-results", validLicenseType)?.type == "full";
                if (!isAllowed && authUserUseSubscription) {
                    state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.eventResults")})
                    state.showResultSetup.setValue(null)
                }
                break
        }
    }, [state.showResultSetup.getValue()])

    useEffect(() => {
        if (!eventID) return
        requestAvailableSessions()
    }, [eventID])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function requestAvailableSessions() {
        let sessions = await api.result.getResultableSessions(eventID)
        let sortedSessions: ISessionData[] = []
        if (eventChildrenOrder) {
            eventChildrenOrder.forEach(id => {
                const orderedSession = sessions.filter(session => session._id == id)[0];
                if (orderedSession) {
                    sortedSessions.push(orderedSession);
                }
                sessions = sessions.filter(session => session._id != id);
            })
            sortedSessions = sortedSessions.concat(sessions);
        } else {
            sortedSessions = sessions
        }
        const options = sortedSessions?.map((session: ISessionData) => {
            return {
                value: session._id,
                text: session.name
            }
        })
        if (!options || options.length == 0) {
            setSelectedSessionIDs(null)
        }
        setAvailableSessions(options)
        setInitComplete(true)
    }

    async function action() {
        setBusy(true)
        switch (mode) {
            case "create":
                await createResult()
                break
            case "edit":
                await updateResult()
                break
        }
        setBusy(false)
    }

    async function createResult() {
        const response = await api.result.createResult({
            name: name,
            sessionIDs: selectedSessionIDs,
            eventID: eventID,
            discardedSessions: discardedSessions
        });
        if (response.status == 200) {
            const resultData: IResultData = await response.json();
            await router.showRoute(FrontendRoute.EVENT_RESULT(eventOwnerType, eventOwner.path, eventPath, resultData.path));
            closeModal()
            return
        }
        const errorJSON = await response.json();
        setErrorMessage(error.createMessage(errorJSON.error))
    }

    async function updateResult() {
        const response = await api.result.update(resultData._id, {
            name: name,
            discardedSessions: discardedSessions,
            sessions: selectedSessionIDs
        });
        if (response.status == 200) {
            closeModal()
            return
        }
        const errorJSON = await response.json();
        setErrorMessage(error.createMessage(errorJSON))
    }

    function closeModal() {
        state.showResultSetup.setValue(null)
    }

    function isValid(): boolean {
        return nameValid && discardedSessionsValid
    }

    function modalTitle() {
        return dict(`result.${mode}.title`)
            .replace("{RESULT_NAME}", name ?? "")
    }

    function listTitle() {
        switch (selectedSessionIDs?.length) {
            case 0:
            case null:
            case undefined:
                return dict(`result.selectedSessions.none`)
            case 1:
                return dict(`result.selectedSessions.one`)
        }
        return dict(`result.selectedSessions.multi`)
            .replace("{COUNT}", selectedSessionIDs.length.toString())
    }

    function selectionButtunLabel(): string {
        if (!selectedSessionIDs || selectedSessionIDs?.length == 0) {
            return dict(`multiselect.button.select.all`)
        }
        return dict(`multiselect.button.select.none`)
    }

    function toggleSelection() {
        if (!selectedSessionIDs || selectedSessionIDs?.length == 0) {
            return setSelectedSessionIDs(availableSessions.map((option: MultiSelectOptionType) => option.value))
        }
        return setSelectedSessionIDs([])
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="result-setup-modal"
            width="medium"
            closeAction={closeModal}
            footerChildren={<ContentLayout>
                <FormMessage message={errorMessage} type="error"/>
                <LabelButton
                    label={dict(`result.${mode}.button`)}
                    disabled={!isValid()}
                    progressing={busy}
                    onClick={action}
                    style="primary"/>
            </ContentLayout>}>
            <ContentLayout
                justifyContent="space-between"
                columnTemplate="1fr min-content">
                <Headline text={modalTitle()} style="modal"/>
                <LabelButton
                    label={dict("help.modal.title")}
                    style="secondary-small"
                    onClick={() => state.showHelp.setValue("result.help")}/>
            </ContentLayout>
            <ComponentInitializer isPropertyAvailable={initComplete}>
                <ContentLayout>
                    <ContentLayout columnTemplate="1fr 110px" alignItems="end">
                        <Input
                            type="text"
                            id="name"
                            label={dict(`result.name`)}
                            focus={!isMobile}
                            required={true}
                            showInvalid={mode == "edit"}
                            defaultValue={name}
                            onChange={setName}
                            onValidated={setNameValid}/>
                        <Input
                            type="number"
                            label={dict(`result.discardedSessions.title`)}
                            minNumber={0}
                            defaultValue={resultData?.discardedSessions?.toString() ?? "0"}
                            showInvalid={true}
                            onValidated={setDiscardedSessionsValid}
                            onChange={setDiscardedSessions}/>
                    </ContentLayout>
                    <ContentLayout framed={true}>
                        <ContentLayout
                            columns={2}
                            columnTemplate="auto min-content"
                            justifyContent="space-between">
                            <Headline
                                text={listTitle()}
                                style="h5-underlined"/>
                            <LabelButton
                                label={selectionButtunLabel()}
                                disabled={availableSessions?.length == 0}
                                onClick={toggleSelection}
                                style="secondary-small"/>
                        </ContentLayout>
                        <MultiSelectOptionsList
                            showHeader={availableSessions?.length > 10}
                            options={availableSessions}
                            emptyDataInfo={dict(`result.emtpy.data.info`)}
                            minSelected={0}
                            defaultValues={selectedSessionIDs}
                            onChange={setSelectedSessionIDs}/>
                    </ContentLayout>
                </ContentLayout>
                <Spacer height="modal-footer"/>
            </ComponentInitializer>
        </Modal>
    );

}
