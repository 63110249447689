import * as React from "react";
import {useEffect, useState} from "react";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {Infobox} from "../Infobox";
import {useServices} from "../../../hooks/useServices";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {TrackTeaser} from "../../teaser/track/TrackTeaser";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {IGroupData} from "../../../../../shared/models/IGroupData";
import {IUserData} from "../../../../../shared/models/IUserData";

/******************************************************************
 * TracksInfobox
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TracksInfobox(props: {
    group?: IGroupData
    user?: IUserData
    isPreview?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, router, api, dict} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [activeTracks, setActiveTracks] = useState<ITrackData[]>()
    const [loading, setLoading] = useState<boolean>(true)
    const [hasInactiveTracks, setHasInactiveTracks] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadTracks()
    }, [props.group, props.user])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadTracks() {
        const tracks: ITrackData[] = await api.track.ownerTracks(
            isGroup() ? props.group?._id : props.user?._id,
            isGroup() ? "group" : "user",
            true)
        const allowedTracks = tracks?.filter(track => !track._notAllowedToUse)
        allowedTracks?.sort((a, b) => {
            if (!a._id) return -1
            if (!b._id) return 1
            return b._id.localeCompare(a._id)
        })
        setActiveTracks(allowedTracks)
        setLoading(false)
        setHasInactiveTracks(tracks?.some(track => track._notAllowedToUse))
    }

    function title(): string {
        if (!props.isPreview) {
            if (activeTracks?.length == 1) {
                return dict("infobox.tracks.single.title")
            }
            if (activeTracks?.length > 0) {
                return activeTracks.length + " " + dict("infobox.tracks.title")
            }
        }
        return dict("infobox.tracks.title")
    }

    function isGroup(): boolean {
        return !!props.group
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Infobox
            title={title()}
            isPreview={props.isPreview}
            hasData={activeTracks?.length > 0}
            loading={loading}
            noDataInfo={dict(isRouteOwnerAuthUser
                ? "infobox.tracks.empty.info.owner"
                : "infobox.tracks.empty.info.guest")}
            createButtonLabel={dict("infobox.tracks.create.button.label")}
            createButtonOnClick={() => state.showCreateTrack.setValue(true)}
            linkButtonLabel={dict("infobox.tracks.showAll.button.label")}
            linkButtonOnClick={() => router.showRoute(isGroup()
                ? FrontendRoute.GROUP_TRACKS(props.group?.path)
                : FrontendRoute.USER_TRACKS(props.user?.path))}
            helpButtonLabel={dict("infobox.tracks.academy.button.label")}
            helpButtonOnClick={() => state.showAcademy.setValue(dict("academy.id.tracks"))}
            higherDriverLicenseRequiredOwnerInfo={isRouteOwnerAuthUser && hasInactiveTracks
                ? dict("infobox.tracks.full.owner.info")
                : null}>
            <ComponentInitializer isPropertyAvailable={!loading}>
                <ContentLayout>
                    {activeTracks?.slice(0, 3)?.map(trackData => {
                        return <TrackTeaser
                            key={trackData._id}
                            data={trackData}/>
                    })}
                </ContentLayout>
            </ComponentInitializer>
        </Infobox>
    );

}
