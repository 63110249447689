import * as React from "react";
import {LabelButton} from "../../buttons/label/LabelButton";
import {BadgeColorType} from "../../info/badge/BadgeColorType";

/******************************************************************
 * TabBarItem
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TabBarItem(props: {
    name: string,
    size?: "normal" | "small",
    selected: boolean,
    badgeLabel?: string,
    badgeColor?: BadgeColorType,
    onClick?: (name: string) => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="tabbar-item">
            <LabelButton
                label={props.name}
                badgeLabel={props.badgeLabel}
                badgeColor={props.badgeColor}
                style={props.size == "normal"
                    ? props.selected ? "tabbar-selected" : "tabbar"
                    : props.selected ? "tabbar-small-selected" : "tabbar-small"}
                onClick={() => props.onClick ? props.onClick(props.name) : null}/>
        </div>
    );

}
