import * as React from "react";
import {useState} from "react";
import {ContextMenu} from "../ContextMenu";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useServices} from "../../../hooks/useServices";
import {EventStateType} from "../../../../../shared/types/EventStateType";
import {ContextMenuState} from "../elements/ContextMenuState";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {IEventData} from "../../../../../shared/models/IEventData";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {InfoText} from "../../text/infotext/InfoText";
import {EventUtils} from "../../../../../shared/utils/EventUtils";
import {LabelButton} from "../../buttons/label/LabelButton";

/******************************************************************
 * EventStateSelector
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventStateSelector(props: {
    eventData: IEventData
    compact?: boolean
    updateMethode?: (state: EventStateType) => Promise<void>
    onChange?: (state: EventStateType) => void
    disabled?: boolean
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {showGroup} = useRouteStates()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState(false);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function eventID(): string {
        return props.eventData?._id
    }

    function eventDate(): string {
        return props.eventData?.date
    }

    function eventDateUntil(): string {
        return props.eventData?.dateUntil
    }

    function eventState(): EventStateType {
        return props.eventData?.state
    }

    function eventOwnerType(): string {
        return props.eventData?.ownerType
    }

    async function changeState(state: EventStateType) {
        setIsBusy(true)
        await api.event.update(eventID(), {state: state})
        props.onChange?.(state)
        if (props.updateMethode) {
            await props.updateMethode(state)
            await PromisedDelay.wait(0.1)
        }
        setIsBusy(false)
    }

    async function removeDates() {
        setIsBusy(true)
        await api.event.update(props.eventData?._id ?? eventID, {date: null, dateUntil: null})
        props.onChange?.(eventState())
        if (props.updateMethode) {
            await props.updateMethode(eventState())
            await PromisedDelay.wait(0.1)
        }
        setIsBusy(false)
    }

    function isAutoState(): boolean {
        const {hasStartDate, hasEndDate} = getTimeStates()
        return hasStartDate || hasEndDate
    }

    function getTimeStates(): { hasStartDate: boolean, hasEndDate: boolean } {
        if (props.eventData) {
            return {
                hasStartDate: TimeUtils.isValidDate(props.eventData?.date),
                hasEndDate: TimeUtils.isValidDate(props.eventData?.dateUntil)
            }
        }
        return {
            hasStartDate: TimeUtils.isValidDate(eventDate()),
            hasEndDate: TimeUtils.isValidDate(eventDateUntil())
        }
    }

    function autoStateInfo(): string {
        const startDate = EventUtils.computeStartDate(props.eventData)
        const endDate = EventUtils.computeEndDate(props.eventData)
        const startTimestamp = startDate.toISOString()
        const endTimestamp = endDate.toISOString()
        if (TimeUtils.isFuture(startTimestamp)) {
            return dict("event.stateSelector.autoStateInfo.future")
                .replaceAll("{STARTDATE}", TimeUtils.formatDate(startTimestamp))
                .replaceAll("{ENDDATE}", TimeUtils.formatDate(endTimestamp))
        }
        if (TimeUtils.isPast(startTimestamp) && TimeUtils.isFuture(endTimestamp)) {
            return dict("event.stateSelector.autoStateInfo.running")
                .replaceAll("{STARTDATE}", TimeUtils.formatDate(startTimestamp))
                .replaceAll("{ENDDATE}", TimeUtils.formatDate(endTimestamp))
        }
        return dict("event.stateSelector.autoStateInfo.past")
            .replaceAll("{STARTDATE}", TimeUtils.formatDate(startTimestamp))
            .replaceAll("{ENDDATE}", TimeUtils.formatDate(endTimestamp))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="state"
            state={props.eventData?.state}
            isBusy={isBusy}
            compactStateInfo={props.compact}
            stopPropagation={true}
            className="event-state-selector"
            enabled={!props.disabled && (isRouteOwnerAuthUser || isGroupAdmin)}>
            {isAutoState() && <>
                <InfoText
                    text={autoStateInfo()}
                    size="tiny"
                    style="warning"
                    width="tiny"
                    disableSelect={true}/>
                <InfoText
                    text={dict("event.stateSelector.auto.remove")}
                    size="tiny"
                    style="warning"
                    width="tiny"
                    disableSelect={true}/>
                <LabelButton
                    label={dict("event.stateSelector.auto.remove.button")}
                    style="primary-tiny"
                    onClick={removeDates}/>
            </>}
            {!isAutoState() && <>
                {(showGroup || eventOwnerType() == "group") && <>
                    <ContextMenuState
                        state="upcoming"
                        selected={eventState() == "upcoming"}
                        onClick={() => changeState("upcoming")}/>
                    <ContextMenuDivider style="small"/>
                </>}
                <ContextMenuState
                    state="live"
                    selected={eventState() == "live"}
                    onClick={() => changeState("live")}/>
                <ContextMenuDivider style="small"/>
                <ContextMenuState
                    state="finished"
                    selected={eventState() == "finished"}
                    onClick={() => changeState("finished")}/>
            </>}
        </ContextMenu>
    );

}
