import * as React from "react";
import {UserInfo} from "../../../../ui/info/user/UserInfo";
import {useServices} from "../../../../hooks/useServices";
import {useTrackState} from "../../../../hooks/useTrackState";
import {GroupInfo} from "../../../../ui/info/group/GroupInfo";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {PinComponent} from "../../../../ui/utils/observer/PinComponent";
import {TrackStateSelector} from "../../../../ui/context/track/TrackStateSelector";

/******************************************************************
 * TrackSidebarInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackSidebarInfo() {

    /* ----------------------------------------------------------------
 	 * SERVICES
 	 * --------------------------------------------------------------*/

    const {state} = useServices()
    const {
        trackData,
        trackName,
        trackOwnerType,
        trackLayout,
        trackOwner
    } = useTrackState();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <PinComponent className="track-sidebar-info">
            <div className="track-sidebar-info-title">
                <h2>{trackName}</h2>
                <h3>{trackLayout}</h3>
            </div>
            <div className="track-sidebar-info-states">
                {trackOwnerType == "user"
                    ? <UserInfo user={trackOwner} style="small-light" clickToShowProfile={true}/>
                    : <GroupInfo group={trackOwner} style="small-light" clickable={true}/>}
                <IconButton
                    type="settings"
                    stopPropagation={true}
                    scale={0.85}
                    size="small"
                    onClick={() => state.showTrackSettings.setValue(true)}/>
                <TrackStateSelector
                    trackData={trackData}/>
            </div>
        </PinComponent>
    );

}
