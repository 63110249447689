import * as React from "react";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Table} from "../../table/Table";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {useServices} from "../../../hooks/useServices";
import {IconType} from "../../icons/IconType";

/******************************************************************
 * TrackList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackList(props: {
    tracksData: ITrackData[]
    noDataInfo?: string
    readonly?: boolean
    onAddClicked?: (data: ITrackData) => void
    onRemoveClicked?: (data: ITrackData) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function columnsDefinition(): ITableColumnDefinition<ITrackData>[] {
        const addSize = props.onAddClicked ? "60px" : null
        const removeSize = props.onRemoveClicked ? "60px" : null
        return [{
            type: "image",
            dataKey: "image",
            size: "70px",
        },{
            type: "text-bold",
            dataKey: "name",
            sortKey: "name",
            columnName: dict("trackList.name.label"),
            size: "2.5fr"
        }, {
            type: "text",
            dataKey: "layout",
            sortKey: "layout",
            columnName: dict("trackList.name.layout"),
            size: "2.5fr"
        }, {
            type: "privacy",
            dataKey: "privacy",
            sortKey: "privacy",
            size: "50px"
        }, {
            type: "icon-button",
            size: props.readonly ? null : addSize,
            align: "center",
            value: (data) => (data._notAllowedToUse ? "private" : "add") as IconType,
            action: (data: ITrackData) => {
                props.onAddClicked?.(data)
                return null
            }
        }, {
            type: "icon-button",
            size: props.readonly ? null : removeSize,
            align: "center",
            value: () => "cancel" as IconType,
            action: (data: ITrackData) => {
                props.onRemoveClicked?.(data)
                return null
            }
        }];
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="track-list" framed={true} gap="small">
            <Table<ITrackData>
                rowsData={props.tracksData}
                noDataInfo={props.noDataInfo}
                visibleRows={5}
                showFilter={props.tracksData?.length > 5}
                sortKey={"name"}
                sortDirection={"asc"}
                columnsDefinition={columnsDefinition()}
                style="standard-large"/>
        </ContentLayout>
    );

}
