import * as React from "react";
import {TrackSidebarImage} from "../../../ui/image/track/TrackSidebarImage";
import {TrackSidebarInfo} from "./info/TrackSidebarInfo";
import {TrackSidebarMenu} from "./menu/TrackSidebarMenu";
import {useEffect} from "react";
import {useServices} from "../../../hooks/useServices";
import {useTrackState} from "../../../hooks/useTrackState";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useGroupState} from "../../../hooks/useGroupState";
import {useRouteOwner} from "../../../hooks/useRouteOwner";

/******************************************************************
 * TrackSidebar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackSidebar() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {trackPrivacy} = useTrackState();
    const {isGroupAdmin, isGroupMember} = useGroupState()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        redirectToHomeWhenNoRights()
    }, [trackPrivacy])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function redirectToHomeWhenNoRights() {
        switch (trackPrivacy) {
            case "public":
                return
            case "private":
                if (isGroupMember || isRouteOwnerAuthUser) return
                router.showRoute(FrontendRoute.HOME)
            case "admin":
                if (isGroupAdmin || isRouteOwnerAuthUser) return
                router.showRoute(FrontendRoute.HOME)
                break
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section className="track-sidebar">
            <TrackSidebarImage enableMediaView={true}/>
            <TrackSidebarInfo/>
            <TrackSidebarMenu/>
        </section>
    );

}
