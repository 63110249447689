import * as React from "react";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {Picture} from "../../image/Picture";
import {Icon} from "../../icons/Icon";
import {IconType} from "../../icons/IconType";
import {useGroupState} from "../../../hooks/useGroupState";
import {InfoText} from "../../text/infotext/InfoText";
import {useServices} from "../../../hooks/useServices";
import {TrackUtils} from "../../../../utils/TrackUtils";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useEventState} from "../../../hooks/useEventState";

/******************************************************************
 * TrackSidebarButton
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackSidebarButton(props: {
    data: ITrackData
    selected: boolean
    onClick: (trackData: ITrackData) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {isGroupMember, isGroupAdmin} = useGroupState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {eventOwnerType} = useEventState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function privacyIconType(): IconType {
        return TrackUtils.trackPrivacyIconType(props.data.privacy)
    }

    function hasUserAccess(): boolean {
        return TrackUtils.hasUserAccess(
            props.data.privacy,
            isGroupMember,
            isGroupAdmin,
            isRouteOwnerAuthUser)
    }

    function notAvailableText(): string {
        return dict(TrackUtils.trackNotAvailableDictKey(
            props.data.privacy,
            eventOwnerType == "user"))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <button
            className="track-sidebar-button"
            onClick={hasUserAccess() ? () => props.onClick(props.data) : null}
            data-no-access={!hasUserAccess()}
            data-selected={props.selected}>
            {hasUserAccess()
                ? <>
                    <Picture
                        file={props.data.image}
                        fit="cover"
                        framing="rounded-4"
                        allowMediaViewer={true}/>
                    <div className="track-sidebar-button-labels">
                        <h2>{props.data.name}</h2>
                        {props.data.layout && <h3>{props.data.layout}</h3>}
                    </div>
                    {props.data.privacy != "public" &&
                        <div className="track-sidebar-button-privacy-icon">
                            <Icon
                                type={privacyIconType()}
                                scale={0.5}/>
                        </div>}
                </>
                : <>
                    <div className="track-sidebar-button-icon-frame">
                        <Icon
                            type={privacyIconType()}
                            scale={0.8}/>
                    </div>
                    <InfoText
                        text={notAvailableText()}
                        align="left"/>
                </>}
        </button>
    );

}
