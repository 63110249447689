import * as React from "react"
import {useState} from "react"
import {LabelText} from "../../../../ui/text/label/LabelText"
import {useServices} from "../../../../hooks/useServices"
import {EventAddChildrenContextMenu} from "../../../../ui/context/event/EventAddChildrenContextMenu"
import {useRouteOwner} from "../../../../hooks/useRouteOwner"
import {useEventState} from "../../../../hooks/useEventState"
import {EventChildrenMenu} from "./children/EventChildrenMenu";
import {useGroupState} from "../../../../hooks/useGroupState";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useRouteStates} from "../../../../hooks/useRouteStates";
import {TrackSidebarButton} from "../../../../ui/buttons/track/TrackSidebarButton";
import {ITrackData} from "../../../../../../shared/models/submodels/ITrackData";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";
import {EventUtils} from "../../../../../../shared/utils/EventUtils";
import {BadgeColorType} from "../../../../ui/info/badge/BadgeColorType";

/******************************************************************
 * EventSidebarMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventSidebarMenu() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, router} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {
        eventData,
        eventOwner,
        eventState,
        eventAccess,
        eventPath,
        eventChildren,
        eventFolders,
        eventOwnerType,
        eventTracks,
        eventIsFinished,
        eventRequestingDrivers,
        eventPendingDrivers,
    } = useEventState()
    const {routePath} = useRouteStates()
    const {isGroupAdmin} = useGroupState()
    const {showEventHome, showEventRequests} = useRouteStates()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isTraining] = useState<boolean>(eventOwnerType == "user")

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoEventHomePage() {
        router.showRoute(FrontendRoute.EVENT(eventOwnerType, eventOwner.path, eventPath))
    }

    function gotoEventRequestsPage() {
        router.showRoute(FrontendRoute.EVENT_PARTICIPANTS(eventOwnerType, eventOwner.path, eventPath))
    }

    function showEventTrackPage(trackData: ITrackData) {
        router.showRoute(FrontendRoute.EVENT_TRACK(eventOwnerType, eventOwner.path, eventPath, trackData.path))
    }

    function isTrackSelected(trackPath: string) {
        if (!routePath) return false
        return routePath == FrontendRoute.EVENT_TRACK(eventOwnerType, eventOwner.path, eventPath, trackPath)
    }

    function showParticipationButton(): boolean {
        if (eventAccess == "only-registered-drivers") return true
        if (EventUtils.needParticipationRequest(eventData)) {
            return true
        }
        return false
    }

    function requestButtonBadgeLabel(): string {
        return EventUtils.driverStatesInfo(eventData, isGroupAdmin)
    }

    function hasNonRegisteredDrivers(): boolean {
        return eventPendingDrivers?.length > 0 || eventRequestingDrivers?.length > 0
    }

    function driversBadgeColor(): BadgeColorType {
        if (eventIsFinished) return "blue"
        return hasNonRegisteredDrivers() && isGroupAdmin ? "red" : "white"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section className="event-sidebar-menu">
            <header>
                <LabelButton
                    label={dict(isTraining
                        ? "training.overview.menu.title"
                        : "event.overview.menu.title")}
                    selected={showEventHome}
                    style="sidebar"
                    onClick={gotoEventHomePage}/>
                {showParticipationButton() &&
                    <LabelButton
                        label={dict("event.requests.menu.title")}
                        style="sidebar"
                        badgeLabel={requestButtonBadgeLabel()}
                        badgeColor={driversBadgeColor()}
                        selected={showEventRequests}
                        onClick={gotoEventRequestsPage}/>}
                {eventTracks?.length > 0 &&
                    <Divider style="sidebar-menu-full"/>}
                {eventTracks?.length > 0 &&
                    <ContentLayout gap="tiny">
                        <>{eventTracks?.map(track => {
                            return <TrackSidebarButton
                                key={track._id}
                                data={track}
                                selected={isTrackSelected(track.path)}
                                onClick={showEventTrackPage}/>
                        })}</>
                    </ContentLayout>}
                <Divider style="sidebar-menu-full"/>
                <Spacer height="normal"/>
            </header>
            <main>
                {eventChildren?.all?.length > 0 || eventFolders?.length > 0
                    ? <EventChildrenMenu/>
                    : <LabelText label={(isGroupAdmin || isRouteOwnerAuthUser)
                        ? eventOwnerType == "user"
                            ? dict("training.sessions.empty.editor")
                            : dict("event.sessions.empty.editor")
                        : dict("event.sessions.empty.guest")}/>
                }
            </main>
            {eventState != "finished" && (isRouteOwnerAuthUser || isGroupAdmin) && <>
                <ActionBar location="sidebar">
                    <EventAddChildrenContextMenu showAddResult={!isTraining}/>
                </ActionBar>
            </>}
        </section>
    )

}
