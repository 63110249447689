import * as React from "react";
import {ReactElement} from "react";
import {ContentLayout} from "../content/ContentLayout";
import {ScrollBox} from "../../utils/scrollbox/ScrollBox";

/******************************************************************
 * TabBar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TabBar(props: {
    children?: ReactElement | ReactElement[],
    align?: "start" | "center" | "end"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="tab-bar"
            justifyItems={props.align ?? "start"}>
            <ScrollBox
                className={"tab-bar-scrollbox"}
                useFlexX={true}
                gapFlexX="tabs-normal"
                scrollX={true}
                scrollY={false}>
                {props.children}
            </ScrollBox>
        </ContentLayout>
    );

}
