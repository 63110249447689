import * as React from "react";
import {UserInfo} from "../../../../ui/info/user/UserInfo";
import {useEventState} from "../../../../hooks/useEventState";
import {PrivacySelector} from "../../../../ui/context/privacy/PrivacySelector";
import {EventStateSelector} from "../../../../ui/context/state/EventStateSelector";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useServices} from "../../../../hooks/useServices";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {GroupInfo} from "../../../../ui/info/group/GroupInfo";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";
import {PinComponent} from "../../../../ui/utils/observer/PinComponent";

/******************************************************************
 * EventSidebarInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventSidebarInfo() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router, state} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();
    const {eventData, eventName, eventOwner, eventPath, eventOwnerType} = useEventState();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showEventPage() {
        router.showRoute(FrontendRoute.EVENT(eventOwnerType, eventOwner.path, eventPath))
    }

    function isEvent() {
        return eventOwnerType == "group";
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <PinComponent className="event-sidebar-info">
            <div
                className="event-sidebar-info-hitzone"
                onClick={showEventPage}>
            </div>
            <div className="event-sidebar-info-title">
                <Headline
                    text={eventName}
                    style="h2"/>
            </div>
            <div className="event-sidebar-info-states" data-type={isEvent() ? "event" : "training"}>
                {eventOwnerType == "user" &&
                    <UserInfo user={eventOwner} style="small-light" clickToShowProfile={true}/>}
                {eventOwnerType == "group" &&
                    <GroupInfo group={eventOwner} style="small-light" clickable={true}/>}
                {(isGroupAdmin || isRouteOwnerAuthUser) &&
                    <IconButton
                        type="settings"
                        stopPropagation={true}
                        scale={0.85}
                        size="small"
                        onClick={() => state.showEventSettings.setValue(true)}/>}
                <PrivacySelector/>
                {isEvent() &&
                    <EventStateSelector eventData={eventData}/>}
            </div>
        </PinComponent>
    )

}
