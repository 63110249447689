import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {Headline} from "../../../ui/text/headings/Headline";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {IMailData} from "../../../../../shared/models/IMailData";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {MailUtils} from "../../../../../shared/utils/MailUtils";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {Divider} from "../../../ui/utils/divider/Divider";
import {EmptyDataInfo} from "../../../ui/info/empty/EmptyDataInfo";

/******************************************************************
 * MailViewerModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailViewerModal() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const iframeRef = useRef<HTMLIFrameElement>(null);

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, api, dict} = useServices()
    const {authUserID, authUserNick} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [htmlContent, setHtmlContent] = useState('');
    const [mailData, setMailData] = useState<IMailData>()
    const [mailID] = useState<MongoObjectIDType>(state.showMailViewer.getValue())
    const [loading, setLoading] = useState<boolean>(true)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadRenderedMail()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadRenderedMail() {
        if (!mailID || !authUserID) return
        const mail = await api.mail.getMailByID(mailID)
        const html = await api.mail.render(mailID, authUserID, true)
        setHtmlContent(html)
        setMailData(mail)
        setLoading(false)
    }

    function subject() {
        return MailUtils.replacePlaceholders({
            renderedMail: mailData?.subject,
            recipientNick: authUserNick
        })
    }

    function updateIFrameHeight() {
        if (iframeRef.current) {
            iframeRef.current.style.height = iframeRef.current.contentWindow?.document.body.scrollHeight + 'px'
        }
    }

    function hasData(): boolean {
        return !!mailData && !!htmlContent
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="mail-viewer-modal"
            width="large"
            minHeight="medium"
            closeAction={() => state.showMailViewer.setValue(null)}>
            <ComponentInitializer
                isPropertyAvailable={!loading}
                fullHeight={true}>
                {hasData() &&
                    <ContentLayout rowTemplate="min-content auto">
                        <ContentLayout
                            gap="small">
                            <Headline
                                text={subject()}
                                style="h3"/>
                            <Divider style="modal"/>
                            <Headline
                                text={TimeUtils.formatDate(mailData?.sendDate)}
                                style="h5"/>
                            <Divider style="modal"/>
                        </ContentLayout>
                        <iframe
                            ref={iframeRef}
                            onLoad={updateIFrameHeight}
                            srcDoc={htmlContent}
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 5,
                                border: 'none',
                                backgroundColor: 'white'
                            }}
                            title="Mail Viewer"/>
                    </ContentLayout>}
                {!hasData() &&
                    <EmptyDataInfo text={dict("mail.viewer.empty")}/>}
            </ComponentInitializer>
        </Modal>
    );

}
