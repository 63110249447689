import {useEffect, useState} from "react"
import {IUserData} from "../../../shared/models/IUserData"
import {useServices} from "./useServices"
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType"
import {IFileData} from "../../../shared/models/IFileData"
import {IGroupMemberData} from "../../../shared/models/submodels/IGroupMemberData"
import {IGroupData} from "../../../shared/models/IGroupData"
import {GroupUtils} from "../../../shared/utils/GroupUtils";
import {GroupType} from "../../../shared/types/GroupType";
import {IUserSubscription} from "../../../shared/models/submodels/IUserSubscription";
import {DriverLicenseType} from "../../../shared/types/DriverLicenseType";
import {DriverLicenseUtils} from "../../../shared/utils/DriverLicenseUtils";
import {SubscriptionUtils} from "../../utils/SubscriptionUtils";
import {ILocationData} from "../../../shared/types/ILocationData";
import {PrivacyType} from "../../../shared/types/PrivacyType";
import {IArticleData} from "../../../shared/models/IArticleData";

/******************************************************************
 * GroupState Hook
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useGroupState() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [groupData, setGroupData] = useState<IGroupData>(state.group.getValue())
    const [groupType, setGroupType] = useState<GroupType>(state.group.getValue()?.type)
    const [groupID, setGroupID] = useState<MongoObjectIDType>(state.group.getValue()?._id)
    const [groupPrivacy, setGroupPrivacy] = useState<PrivacyType>(state.group.getValue()?.privacy ?? "private")
    const [groupName, setGroupName] = useState<string>(state.group.getValue()?.name)
    const [groupLocation, setGroupLocation] = useState<ILocationData>(state.group.getValue()?.location)
    const [groupPath, setGroupPath] = useState<string>(state.group.getValue()?.path)
    const [groupOwner, setGroupOwner] = useState<IUserData>(state.group.getValue()?.owner)
    const [groupOwnerNick, setGroupOwnerNick] = useState<string>(state.group.getValue()?.owner?.nick)
    const [groupAboutArticle, setGroupAboutArticle] = useState<MongoObjectIDType | IArticleData>(state.group.getValue()?.aboutArticle)
    const [groupAvatar, setGroupAvatar] = useState<IFileData>(state.group.getValue()?.avatar)
    const [groupContact, setGroupContact] = useState<IUserData>(getContact())
    const [groupMembers, setGroupMembers] = useState<IGroupMemberData[]>(state.group.getValue()?.members)
    const [isGroupOwner, setIsGroupOwner] = useState<boolean>(getIsGroupOwner())
    const [isGroupAdmin, setIsGroupAdmin] = useState<boolean>(getIsGroupAdmin())
    const [isGroupMember, setIsGroupMember] = useState<boolean>(getIsGroupMember())
    const [groupOwnerSubscription, setGroupOwnerSubscription] = useState<IUserSubscription>(state.group.getValue()?.owner.subscription ?? {driverLicense: "free"});
    const [groupOwnerPaddleCustomerID, setGroupOwnerPaddleCustomerID] = useState<string>(state.group.getValue()?.owner.paddleCustomerID ?? null);
    const [groupOwnerHasUnpaidSubscription, setGroupOwnerHasUnpaidSubscription] = useState<boolean>(hasUnpaidSubscription());
    const [groupOwnerValidDriverLicense, setGroupOwnerValidDriverLicense] = useState<DriverLicenseType>(getValidDriverLicense());

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateGroupStates()
        state.group.onChangeSignal.add(updateGroupStates)
        return () => {
            state.group.onChangeSignal.remove(updateGroupStates)
        }
    }, [])

    useEffect(() => {
        updateGroupStates()
    }, [state.group.getValue()])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateGroupStates() {
        const group: IGroupData = state.group.getValue()
        setGroupData(group)
        setGroupType(group?.type)
        setGroupID(group?._id)
        setGroupPrivacy(group?.privacy ?? "private")
        setGroupName(group?.name)
        setGroupLocation(group?.location)
        setGroupAboutArticle(group?.aboutArticle)
        setGroupPath(group?.path)
        setGroupAvatar(group?.avatar)
        setGroupContact(getContact())
        setGroupMembers(group?.members)
        setIsGroupOwner(getIsGroupOwner())
        setIsGroupAdmin(getIsGroupAdmin())
        setIsGroupMember(getIsGroupMember())
        setGroupOwner(group?.owner)
        setGroupOwnerNick(group?.owner?.nick)
        setGroupOwnerSubscription(group?.owner?.subscription ?? {driverLicense: "free"});
        setGroupOwnerPaddleCustomerID(group?.owner?.paddleCustomerID ?? null);
        setGroupOwnerHasUnpaidSubscription(hasUnpaidSubscription());
        setGroupOwnerValidDriverLicense(getValidDriverLicense());
    }

    function getIsGroupOwner(): boolean {
        const group: IGroupData = state.group.getValue()
        const authUser: IUserData = state.authUser.getValue()
        return !!authUser && group?.owner?._id == authUser._id
    }

    function getIsGroupAdmin(): boolean {
        return GroupUtils.isAdmin(state.group.getValue(), state.authUser.getValue())
    }

    function getIsGroupMember(): boolean {
        return GroupUtils.isMember(state.group.getValue(), state.authUser.getValue())
    }

    function hasUnpaidSubscription() {
        const user: IUserData = state.group.getValue()?.owner;
        if (!user?.subscription?.driverLicense) return false;
        return user?.subscription?.driverLicense != "free" && !user?.subscription?.isPaid
    }

    function getValidDriverLicense(): DriverLicenseType {
        const user: IUserData = state.group.getValue()?.owner;
        if (SubscriptionUtils.skipSubscriptionService(user)) return null
        return DriverLicenseUtils.validLicenseType(user?.subscription)
    }

    function getContact(): IUserData {
        return GroupUtils.getContact(state.group.getValue())
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        groupData,
        groupType,
        groupID,
        groupAboutArticle,
        groupName,
        groupPrivacy,
        groupLocation,
        groupPath,
        groupAvatar,
        groupContact,
        groupMembers,
        isGroupOwner,
        isGroupAdmin,
        isGroupMember,
        groupOwner,
        groupOwnerNick,
        groupOwnerSubscription,
        groupOwnerPaddleCustomerID,
        groupOwnerHasUnpaidSubscription,
        groupOwnerValidDriverLicense
    }
}
