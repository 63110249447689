import * as React from "react";
import {useRef} from "react";
import {useServices} from "../../../../hooks/useServices";
import {useTrackState} from "../../../../hooks/useTrackState";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../ui/form/elements/input/Input";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";
import {TrackSidebarImage} from "../../../../ui/image/track/TrackSidebarImage";
import {SelectInput, SelectInputOptionType} from "../../../../ui/form/elements/select/SelectInput";
import {PrivacyType} from "../../../../../../shared/types/PrivacyType";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {Divider} from "../../../../ui/utils/divider/Divider";

/******************************************************************
 * TrackGeneralSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackGeneralSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const [isMobile] = useMobileStyle(rootRef, 560)
    const {trackID, trackName, trackLayout, trackPrivacy, trackIsArchived} = useTrackState()
    const {isRouteOwnerAuthUser, isRouteOwnerUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateName(name: string): Promise<Response> {
        await api.track.update(trackID, {name: name})
        return null
    }

    async function updateLayout(layout: string): Promise<Response> {
        await api.track.update(trackID, {layout: layout})
        return null
    }

    function editable(): boolean {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    function privacyOptions(): SelectInputOptionType[] {
        if (isRouteOwnerUser) {
            return [
                {value: "public" as PrivacyType, text: dict("event.privacy.public")},
                {value: "private" as PrivacyType, text: dict("training.privacy.private")}
            ]
        }
        return [
            {value: "public" as PrivacyType, text: dict("event.privacy.public")},
            {value: "private" as PrivacyType, text: dict("event.privacy.private")},
            {value: "admin" as PrivacyType, text: dict("event.privacy.admin")}
        ]
    }

    async function updatePrivacy(privacy: PrivacyType): Promise<Response> {
        return await api.track.update(trackID, {privacy: privacy})
    }

    async function toggleArchive() {
        await api.track.update(trackID, {isArchived: !trackIsArchived})
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="track-general-settings"
            ref={rootRef}
            columnTemplate={isMobile ? null : "200px 1fr"}>
            <ContentLayout>
                <TrackSidebarImage
                    editable={true}
                    enableMediaView={false}
                    framing="rounded-8"/>
            </ContentLayout>
            <ContentLayout>
                <Input
                    type="text"
                    label={dict("track.name.label")}
                    readonly={!editable()}
                    defaultValue={trackName}
                    action={updateName}/>
                {(editable() || trackLayout) &&
                    <Input
                        type="text"
                        label={dict("track.layout.label")}
                        readonly={!editable()}
                        helpTopic="track.layout"
                        defaultValue={trackLayout}
                        action={updateLayout}/>}
                <SelectInput
                    label={dict("track.privacy.name")}
                    defaultValue={trackPrivacy}
                    options={privacyOptions()}
                    onChange={updatePrivacy}/>
                <Divider style="modal"/>
                <ContentLayout justifyItems="start">
                    <LabelButton
                        label={trackIsArchived
                            ? dict("track.unarchive.label")
                            : dict("track.archive.label")}
                        style={"primary"}
                        onClick={toggleArchive}/>
                </ContentLayout>
            </ContentLayout>
        </ContentLayout>
    );

}
