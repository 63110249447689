import * as React from "react";
import {useEffect, useState} from "react";
import {JoinedEventsList} from "../../../../ui/list/events/JoinedEventsList";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {BasicPageTitle} from "../../../../ui/text/headings/BasicPageTitle";
import {ContextMenu} from "../../../../ui/context/ContextMenu";
import {ContextMenuSelectable} from "../../../../ui/context/elements/ContextMenuSelectable";

/******************************************************************
 * UserEventsPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserEventsPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {isRouteOwnerAuthUser, routeOwnerHiddenEvents, routeOwnerName, routeOwnerNick} = useRouteOwner()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [selectedFilter, setSelectedFilter] = useState<"visible" | "hidden">("visible");

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!routeOwnerHiddenEvents || routeOwnerHiddenEvents.length == 0) {
            setSelectedFilter("visible")
        }
    }, [routeOwnerHiddenEvents])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="user-events-page"
            gap="none"
            rowTemplate="min-content 1fr">
            <BasicPageTitle
                title={dict("events.user.title")}
                subTitle={routeOwnerNick ?? routeOwnerName}/>
            <JoinedEventsList filter={selectedFilter}/>
        </ContentLayout>
        <ActionBar location="main">
            {isRouteOwnerAuthUser && <>
                <LabelButton
                    label={dict("event.join.create.button.label")}
                    onClick={() => state.showArticle.setValue(dict("article.id.joinCreateEvent"))}
                    style={"action-main"}/>
                <Divider style="action-bar"/>
            </>}
            {routeOwnerHiddenEvents?.length > 0 && <>
            {!isRouteOwnerAuthUser &&
                <ActionBarSpacer/>}
                <ContextMenu
                    type="icon"
                    iconType={selectedFilter}
                    iconColor={selectedFilter == "hidden" ? "yellow" : null}
                    iconScale={1.0}
                    iconSize="small">
                    <ContextMenuSelectable
                        label={dict("events.filter.visible.label")}
                        icon="visible"
                        selected={selectedFilter == "visible"}
                        onClick={() => setSelectedFilter("visible")}/>
                    <ContextMenuSelectable
                        label={dict("events.filter.hidden.label")}
                        icon="hidden"
                        selected={selectedFilter == "hidden"}
                        onClick={() => setSelectedFilter("hidden")}/>
                </ContextMenu>
                <Divider style="action-bar"/>
            </>}
            {(!routeOwnerHiddenEvents || routeOwnerHiddenEvents.length == 0) && !isRouteOwnerAuthUser &&
                <ActionBarSpacer/>}
            <IconButton
                type="academy"
                size="small"
                onClick={() => state.showAcademy.setValue(dict("academy.id.events"))}/>
            <ActionBarSpacer/>
        </ActionBar>
    </>

}
