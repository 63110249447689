import * as React from "react";
import {PrivacyType} from "../../../../../../shared/types/PrivacyType";
import {PrivateIcon} from "../../../icons/PrivateIcon";
import {HiddenIcon} from "../../../icons/HiddenIcon";

/******************************************************************
 * TablePrivacyCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TablePrivacyCell(props: {
    state: PrivacyType
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-privacy-cell">
            {props.state == "private" && <PrivateIcon scale={0.8}/>}
            {props.state == "admin" && <HiddenIcon scale={0.8}/>}
        </div>
    );

}
