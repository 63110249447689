import * as React from "react";

/******************************************************************
 * ArchiveIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArchiveIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="archive-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.25 18.4497C1.25 19.1411 1.80859 19.6997 2.5 19.6997H17.5C18.1914 19.6997 18.75 19.1411 18.75 18.4497V7.19971H1.25V18.4497ZM7.5 10.1685C7.5 9.91064 7.71094 9.69971 7.96875 9.69971H12.0312C12.2891 9.69971 12.5 9.91064 12.5 10.1685V10.481C12.5 10.7388 12.2891 10.9497 12.0312 10.9497H7.96875C7.71094 10.9497 7.5 10.7388 7.5 10.481V10.1685ZM18.75 2.19971H1.25C0.558594 2.19971 0 2.7583 0 3.44971V5.32471C0 5.66846 0.28125 5.94971 0.625 5.94971H19.375C19.7188 5.94971 20 5.66846 20 5.32471V3.44971C20 2.7583 19.4414 2.19971 18.75 2.19971Z"
                    fill="white"/>
            </svg>
        </div>
    );

}


